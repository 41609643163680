import {createSlice} from "@reduxjs/toolkit";
import {IAuthState} from "./authTypes";
import {changePassword, login} from "./authActions";


const initialState: IAuthState = {
   accessToken: localStorage.getItem('accessToken') || '',
   error: '',
   changePassword: {
      isError: false,
      isSuccess: false,
      message: ''
   }
}

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      logout: state => {
         state.accessToken = ''
         localStorage.removeItem('accessToken')
      }
   },
   extraReducers: {
      [login.fulfilled.type]: (state, {payload}) => {
         localStorage.setItem('accessToken', payload.accessToken)
         state.accessToken = payload.accessToken
         state.error = ''
      },
      [login.rejected.type]: (state, {payload}) => {
         state.error = payload
      },
      [changePassword.fulfilled.type]: (state) => {
         state.changePassword.isError = false
         state.changePassword.isSuccess = true
         state.changePassword.message = 'Գաղտնաբառն հաջողությամբ փոփոխվել է'
      },
      [changePassword.rejected.type]: (state) => {
         state.changePassword.isError = true
         state.changePassword.isSuccess = false
         state.changePassword.message = 'Գաղտնաբառի վերականգնման հղման ժամկետը լրացել է'
      }
   }
})

export const {logout} = authSlice.actions

export default authSlice
