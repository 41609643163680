import axios from "axios";
import {ILessonTasks} from "../../types/lesson/ILesson";

export class TasksApi {

   static async getTasks(tasksId: string) {
      const {data} = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/tasks/${tasksId}`)
      return data
   }

   static async updateTasks(tasksId: string, tasks: ILessonTasks) {
      const {data} = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/tasks/${tasksId}`, tasks)
      return data
   }
}
