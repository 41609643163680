import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import EditTask from "./EditTask";
import {TaskType, TaskTypes} from "../../types/lesson/level/task/Task";
import {ILessonTasks} from "../../types/lesson/ILesson";
import _ from 'lodash'
import {TasksApi} from "../../api/nest/TasksApi";
import DashboardLayout from "../../layouts/DashboardLayout";
import {useTypedSelector} from "../../store";
import {useDispatch} from "react-redux";
import useAlert from "../../hooks/useAlert";

const EditTasks = () => {
   const {tasksId} = useParams();
   const dispatch = useDispatch()
   const {showToast, showError} = useAlert()
   const [tasks, setTasks] = useState<ILessonTasks>({
      data: []
   });
   const [newTaskType, setNewTaskType] = useState<TaskTypes>();

   useEffect(() => {
      TasksApi.getTasks(tasksId as string).then(data => setTasks(data))
   }, [])

   const saveTask = (taskNumber: number, taskData: TaskType) => {
      const newTasksData = {...tasks};


      newTasksData.data[taskNumber] = taskData;

      console.log(newTasksData);

      setTasks(newTasksData);
   };

   const addTask = () => {
      // if (tasks && tasks[newTaskType]) {
      //    return;
      // }

      const newTasksData = {...tasks};

      const number = tasks.data.length

      newTasksData.data.push({
         number,
         type: newTaskType as TaskTypes,
         steps: []
      });

      setTasks(newTasksData);
   };

   const finalSaveTasks = async () => {
      console.log(tasks)
      TasksApi.updateTasks(tasksId as string, tasks)
         .then(() => {
            showToast('')
         })
         .catch(e => {
            showError(e.message)
         })
      // saveTasksRequest(tasksId, tasks);
   };

   // const keys = useMemo(() => Object.keys(tasks || {}), [tasks])

   const values = useMemo(() => {
      return _.orderBy(tasks.data, task => task.number)
   }, [tasks])
   // const keys = useMemo(() => Object.keys(tasks), [tasks])


   return (
      <DashboardLayout>
         <div className="border border-3 border-danger p-4">
            <h1>Edit tasks - {tasksId}</h1>

            <hr/>

            {/*{JSON.stringify(tasks, null, 2)}*/}

            {values &&
               values.map((task) => {
                  return <EditTask saveTask={saveTask} task={task} taskNumber={task.number} key={task.number}/>;
               })}

            <div className="d-flex justify-content-between">
               <div>
                  <select
                     className="form-select"
                     onChange={(e) => setNewTaskType(e.target.value as TaskTypes)}
                  >
                     <option defaultValue="">Select new task type</option>
                     {Object.values(TaskTypes).map(taskType => (
                        <option value={taskType} key={taskType}>
                           {taskType} --- {values.filter((el: any) => el.type === taskType).length}
                        </option>
                     ))}
                  </select>

                  <button className="btn btn-primary my-2"
                          disabled={!newTaskType}
                          onClick={addTask}>
                     Add task
                  </button>
               </div>

               <div>
                  <button className="btn btn-danger my-2" onClick={finalSaveTasks}>
                     Final save tasks
                  </button>
               </div>
            </div>
         </div>
      </DashboardLayout>
   );
};

export default EditTasks;
