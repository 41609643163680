import React, {ChangeEvent, FC, useState, KeyboardEvent} from "react";
import {
   ICollectSentenceFromAudioStep,
} from "../../types/lesson/level/task/step/TaskStep"
import {replaceSpacesFromString} from "../../utils/common";
import _ from "lodash";
import { translate } from "../../utils/translation"

interface EditCollectSentenceFromAudioStepProps {
   step: ICollectSentenceFromAudioStep;
   stepIndex: number;
   saveStep: (stepIndex: number, stepData: any) => void;
}

const EditCollectSentenceFromAudioStep: FC<EditCollectSentenceFromAudioStepProps> = ({step, stepIndex, saveStep}) => {
   const [stepData, setStepData] = useState(step);

   const [newWord, setNewWord] = useState("");

   const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setStepData({
         ...stepData,
         [event.target.name]: event.target.value,
      });
   };

   const deleteWordFromWords = (index: number) => {
      const newWords = [...stepData.words];

      newWords.splice(index, 1);

      setStepData({...stepData, words: newWords});
   };

   const addWord = () => {
      if (!newWord) {
         return;
      }

      setStepData({...stepData, words: [...stepData.words, newWord]});
      setNewWord("");
   };

   const fillWords = () => {
      const newWords = _.shuffle([
         ...stepData.words,
         ...stepData.sentence.split(" "),
      ]);

      setStepData({...stepData, words: newWords});
   };

   const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
         addWord();
      }
   };


   const shuffleWords = () => {
      setStepData({...stepData, words: _.shuffle(stepData.words)});
   }

   const handleTranslateSentence = async () => {
      const translation = await translate(stepData?.sentence)
      setStepData({...stepData, translation: translation.charAt(0).toUpperCase() + translation.slice(1) });
   }

   return (
      <div className="border border-success border-2 p-4 mb-4">
         {/*<h3>Edit step</h3>*/}

         {/*<div>{JSON.stringify(stepData)}</div>*/}

         <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${replaceSpacesFromString(stepData.sentence)}`}
            aria-expanded="false"
            aria-controls={`#${replaceSpacesFromString(stepData.sentence)}`}
         >
            {stepData.sentence}
         </button>

         <div
            className="collapse"
            id={`${replaceSpacesFromString(stepData.sentence)}`}
         >
            <div className="my-3 border border-warning p-4">
               <div className="d-flex align-items-end">
                  <div className="w-75">
                     <label className="form-label">Sentence</label>
                     <input
                        type="text"
                        name="sentence"
                        className="form-control"
                        placeholder="Sentence"
                        defaultValue={stepData?.sentence}
                        onChange={handleInputChange}
                     />
                  </div>
                  <div className="mx-4">
                     <button onClick={fillWords} className="btn btn-warning">
                        Fill the words
                     </button>
                  </div>
               </div>
            </div>

            <div className="my-3 border border-warning p-4">
               <div className="d-flex align-items-end">
                  <div className="w-75">
                     <label className="form-label">Translation - ՀԱՅԵՐԵՆ</label>
                     <input
                        type="text"
                        name="translation"
                        className="form-control"
                        placeholder="Translation"
                        defaultValue={stepData?.translation}
                        onChange={handleInputChange}
                     />
                  </div>
                  <div className="mx-4">
                     <button onClick={handleTranslateSentence} className="btn btn-warning">Translate</button>
                  </div>
               </div>
            </div>


            <div className="mb-3 border border-warning p-4">
               <label className="form-label">Words</label>
               <div className="d-flex my-3">
                  {stepData?.words?.map((word: string, index: number) => (
                     <button
                        onClick={() => deleteWordFromWords(index)}
                        className="btn btn-primary me-3"
                        key={index}
                     >
                        {word}
                     </button>
                  ))}
               </div>

               <div className="d-flex">
                  <input
                     type="text"
                     className="form-control w-25"
                     placeholder="New word for answers"
                     value={newWord}
                     onChange={(e) => setNewWord(e.target.value)}
                     onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <button
                     onClick={addWord}
                     disabled={!newWord}
                     className="btn btn-warning ms-3"
                  >
                     +
                  </button>
                  <button
                     onClick={shuffleWords}
                     className="btn btn-warning ms-3"
                  >
                     shuffle
                  </button>
               </div>
            </div>


            <button
               className="btn btn-success"
               disabled={!stepData.sentence || !stepData.words.length}
               onClick={() => saveStep(stepIndex, stepData)}
            >
               Save step with index - {stepIndex}
            </button>
         </div>
      </div>
   );
};

export default EditCollectSentenceFromAudioStep;
