import React, { ChangeEvent, FC, useState, KeyboardEvent } from "react";
import {
  ICollectSentenceFromWordsStep,
  ITranslateSentenceFromAudioStep,
} from "../../types/lesson/level/task/step/TaskStep";
import { replaceSpacesFromString } from "../../utils/common";
import _ from "lodash";

interface TranslateCollectSentenceFromAudioStepProps {
  step: ITranslateSentenceFromAudioStep;
  stepIndex: number;
  saveStep: (stepIndex: number, stepData: any) => void;
  deleteStep: (stepIndex: number) => void;
}

const TranslateCollectSentenceFromAudioStep: FC<
  TranslateCollectSentenceFromAudioStepProps
> = ({ step, stepIndex, saveStep, deleteStep }) => {
  const [stepData, setStepData] = useState(step);

  const [newWord, setNewWord] = useState("");
  const [newCorrectAnswer, setNewCorrectAnswer] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStepData({
      ...stepData,
      [event.target.name]: event.target.value,
    });
  };

  const deleteWordFromWords = (index: number) => {
    const newWords = [...stepData.words];

    newWords.splice(index, 1);

    setStepData({ ...stepData, words: newWords });
  };

  const addWord = () => {
    if (!newWord) {
      return;
    }

    setStepData({ ...stepData, words: [...stepData.words, newWord] });
    setNewWord("");
  };

  const fillWords = () => {
    const newWords = _.shuffle([
      ...stepData.words,
      ...stepData.correctAnswers[0].split(" "),
    ]);

    setStepData({ ...stepData, words: newWords });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      addWord();
    }
  };

  const editCorrectAnswer = (
     event: ChangeEvent<HTMLInputElement>,
     index: number
  ) => {
    const newStepData = { ...stepData };

    newStepData.correctAnswers[index] = event.target.value;

    setStepData(newStepData);
  };
  const deleteCorrectAnswer = (index: number) => {
    const newStepData = { ...stepData };

    newStepData.correctAnswers.splice(index, 1)

    setStepData(newStepData);
  };

  const addNewCorrectAnswer = () => {
    if (!newCorrectAnswer) {
      return;
    }

    const newStepData = { ...stepData };

    newStepData.correctAnswers.push(newCorrectAnswer);

    setNewCorrectAnswer("");

    setStepData(newStepData);
  };


  const shuffleWords = () => {
    setStepData({...stepData, words: _.shuffle(stepData.words)});
  }


  return (
    <div className="border border-success border-2 p-4 mb-4">
      {/*<h3>Edit step</h3>*/}

      {/*<div>{JSON.stringify(stepData)}</div>*/}

      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${replaceSpacesFromString(stepData.sentence)}`}
        aria-expanded="false"
        aria-controls={`#${replaceSpacesFromString(stepData.sentence)}`}
      >
        {stepData.sentence}
      </button>

      <div
        className="collapse"
        id={`${replaceSpacesFromString(stepData.sentence)}`}
      >
        <div className="my-3 border border-warning p-4">
          <div className="d-flex align-items-end">
            <div className="w-75">
              <label className="form-label">Sentence</label>
              <input
                type="text"
                name="sentence"
                className="form-control"
                placeholder="Sentence"
                defaultValue={stepData?.sentence}
                onChange={handleInputChange}
              />
            </div>

          </div>
        </div>

        <div className="mb-3 border border-warning p-4">
          <label className="form-label">Words</label>
          <div className="d-flex my-3">
            {stepData?.words?.map((word: string, index: number) => (
              <button
                onClick={() => deleteWordFromWords(index)}
                className="btn btn-primary me-3"
                key={index}
              >
                {word}
              </button>
            ))}
          </div>

          <div className="d-flex">
            <input
              type="text"
              className="form-control w-25"
              placeholder="New word for answers"
              value={newWord}
              onChange={(e) => setNewWord(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <button
              onClick={addWord}
              disabled={!newWord}
              className="btn btn-warning ms-3"
            >
              +
            </button>
            <button
               onClick={shuffleWords}
               className="btn btn-warning ms-3"
            >
              shuffle
            </button>
          </div>
        </div>

        <div className="mb-3 border border-warning p-4">
          <label className="form-label">Correct answers</label>
          {stepData?.correctAnswers?.map(
            (correctAnswer: string, index: number) => (
              <div className="d-flex align-items-center" key={correctAnswer}>
                <input
                  type="text"
                  className="form-control w-75 my-3"
                  placeholder={`Correct answer ${index}`}
                  defaultValue={correctAnswer}
                  onChange={(event) => editCorrectAnswer(event, index)}
                />
                <div>
                  <button
                    onClick={() => deleteCorrectAnswer(index)}
                    className="btn btn-danger ms-3"
                  >
                    Delete
                  </button>
                </div>
              </div>
            )
          )}

          <div className="d-flex">
            <input
              type="text"
              className="form-control w-75"
              value={newCorrectAnswer}
              placeholder="New sentence for correct answers"
              onChange={(e) => setNewCorrectAnswer(e.target.value)}
            />
            <button
              disabled={!newCorrectAnswer}
              className="btn btn-warning ms-3"
              onClick={addNewCorrectAnswer}
            >
              +
            </button>
          </div>

          <div className="mt-4">
            <button onClick={fillWords} className="btn btn-warning">
              Fill the words
            </button>
          </div>
        </div>

        <button
          className="btn btn-success"
          disabled={!stepData.sentence || !stepData.words.length}
          onClick={() => saveStep(stepIndex, stepData)}
        >
          Save step with index - {stepIndex}
        </button>
        <button
           className="btn btn-danger ms-3"
           onClick={() => deleteStep(stepIndex)}
        >
          Delete step
        </button>
      </div>
    </div>
  );
};

export default TranslateCollectSentenceFromAudioStep;
