import React from "react";
import { INewLesson } from "../../types/lesson/ILesson";
import AddLessonForm from "../../components/lesson/AddLessonForm";
import {LessonsApi} from "../../api/nest/LessonsApi";
import {useNavigate} from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";

const EditLesson = () => {

   const navigate = useNavigate()

  const saveLesson = async (lessonData: INewLesson) => {
     const lesson = await LessonsApi.createLesson(lessonData)

     navigate(`/lessons/show/${lesson._id}`)
  };

  return (
    <DashboardLayout>
      <h1 className="text-center">Add lesson</h1>

      <AddLessonForm saveLesson={saveLesson} />
    </DashboardLayout>
  );
};

export default EditLesson;
