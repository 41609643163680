import React, {FC, useEffect} from 'react';
import {useTypedSelector} from "../store";
import {useNavigate} from "react-router-dom";
import Navbar from "../components/Navbar";

const GuestLayout: FC = ({children}) => {

   const navigate = useNavigate()
   const {accessToken} = useTypedSelector(state => state.auth)

   useEffect(() => {
      if (accessToken) {
         navigate('/')
      }
   }, [accessToken])


   return (
      <div className="container pt-5">
         {children}
      </div>
   );
};

export default GuestLayout;
