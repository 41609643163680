import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk(
   'authThunk/login',
   async ({email, password}: { email: string, password: string }, thunkAPI) => {
      try {
         const {data} = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/auth/login`, {
            email,
            password
         })
         return thunkAPI.fulfillWithValue(data)
      } catch (e: any) {
         return thunkAPI.rejectWithValue(e.response.data.code)
      }
   }
)


export const changePassword = createAsyncThunk(
   'authThunk/changePassword',
   async ({password, passwordConfirmation, token}: { password: string, passwordConfirmation: string, token: string }, thunkAPI) => {
      try {
         const {data} = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/changePassword`, {
            password,
            passwordConfirmation,
            token
         })
         return thunkAPI.fulfillWithValue(data)
      } catch (e: any) {
         return thunkAPI.rejectWithValue(e.response.data.message)
      }
   }
)
