import React from "react"
import { useParseLessonsMutation, useParserLoginMutation } from "../../store/modules/parser/parserApi"
import { setParserUser } from "../../store/modules/parser/parserSlice"
import { useDispatch } from "react-redux"
import { useTypedSelector } from "../../store"

const ParserNavbar = () => {
   const dispatch = useDispatch()
   const {user, lessons} = useTypedSelector(state => state.parser)

   const [parserLogin] = useParserLoginMutation()

   const handleLogin = () => {
      parserLogin({
         email: "gevo14m@mail.ru",
         password: "098804632gevorg"
      })
         .unwrap()
         .then((user) => {
            dispatch(setParserUser(user))
         })
   }

   return (
      <div className="d-flex align-items-center mb-4">
         <h1>Parser</h1>
         <div>
            <button
               className="btn btn-primary ms-5"
               onClick={handleLogin}
            >
               Login
            </button>
         </div>
         <div className="mx-3">
            Logged in as: {user?.email} <span className="fw-bold">{user?.token}</span>
         </div>
      </div>
   )
}

export default ParserNavbar
