import React, {Fragment, useEffect, useState} from "react";
import {ILesson, LessonCategory} from "../../types/lesson/ILesson";
import {Link} from "react-router-dom";
import {Routes} from "../../enums/routeEnums";
import {LessonsApi} from "../../api/nest/LessonsApi";
import DashboardLayout from "../../layouts/DashboardLayout";
import LessonsTable from "./LessonsTable";

type LessonsResponse = {
   [key in LessonCategory]?: ILesson[];
};


const Lessons = () => {
   const [lessons, setLessons] = useState<LessonsResponse>({});

   useEffect(() => {
      LessonsApi.getAllLessons().then((lessons) => setLessons(lessons));
   }, []);

   const deleteLesson = (lessonId: string) => {
      console.log('not implemented yet')
      // deleteLessonRequest(lessonId);
   };

   return (
      <DashboardLayout>
         <h1 className="text-center mb-5">Lessons</h1>

         <div className="d-flex mb-3">
            <Link to={`/${Routes.LESSONS}/add`} className="btn btn-primary">
               Add lesson
            </Link>
         </div>

         {
            Object.keys(lessons).map(category => {
               return <LessonsTable
                  key={category}
                  category={category}
                  lessons={lessons[category]}
                  onDeleteLesson={deleteLesson}/>
            })
         }
      </DashboardLayout>
   );
};

export default Lessons;
