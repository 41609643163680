import React from "react"
import DashboardLayout from "../../layouts/DashboardLayout"
import { useTypedSelector } from "../../store"
import ParsedLessonsTable from "./ParsedLessonsTable"
import ParserNavbar from "./ParserNavbar"

const Parser = () => {
   const {lessons} = useTypedSelector(state => state.parser)

   return (
      <DashboardLayout>
         <ParserNavbar />

         <ParsedLessonsTable lessons={lessons}/>

      </DashboardLayout>
   )
}

export default Parser
