import React, { FC, useState } from "react"
import EditSelectCorrectWordStep from "../step/EditSelectCorrectWordStep"
import { TaskType, TaskTypes } from "../../types/lesson/level/task/Task"
import EditCollectSentenceFromWordsStep from "../step/EditCollectSentenceFromWordsStep"
import {
   ICollectSentenceFromAudioStep,
   ICollectSentenceFromWordsStep,
   IMatchWordsStep,
   ISelectCorrectWordStep,
   ITheoryStep,
   ITranslateSentenceFromAudioStep
} from "../../types/lesson/level/task/step/TaskStep"
import EditCollectSentenceFromAudioStep from "../step/EditCollectSentenceFromAudioStep"
import EditTranslateSentenceFromAudioStep from "../step/EditTranslateSentenceFromAudioStep"
import EditMatchWordsStep from "../step/EditMatchWordsStep"
import EditTheoryStep from "../step/EditTheoryStep"
import { useTypedSelector } from "../../store"
import {
   transformCollectFromAudio,
   transformMatchWords,
   transformSentenceLearningToNative,
   transformSentenceNativeToLearning,
   transformTheory,
   transformTranslateFromAudio,
   transformWordsLearningToNative,
   transformWordsNativeToLearning
} from "../../utils/transformRawData"

interface EditTaskProps {
   task: any;
   saveTask: (taskNumber: number, taskData: TaskType) => void;
   taskNumber: number
}

const EditTask: FC<EditTaskProps> = ({ task, saveTask, taskNumber }) => {
   const [taskData, setTaskData] = useState<any>(task)
   const [fillFromRawDataLoading, setFillFromRawDataLoading] = useState(false)

   const { currentLesson } = useTypedSelector(state => state.parser)

   const saveStep = (stepIndex: number, updatedStepData: any) => {
      const newTaskData = { ...taskData }

      newTaskData.steps[stepIndex] = updatedStepData

      setTaskData(newTaskData)

      saveTask(newTaskData.number, newTaskData)
   }

   const addStep = () => {
      const newTaskData = { ...taskData }

      const defaultText = "____"

      const stepWithDefaultTextExists = newTaskData.steps.find((el: any) => el.word == defaultText || el.sentence == defaultText)

      if (stepWithDefaultTextExists) {
         console.log("stepWithDefaultTextExists")
         return
      }

      switch (taskData.type) {
         case TaskTypes.WORDS_LEARNING_TO_NATIVE: {
            const newStep: ISelectCorrectWordStep = {
               word: defaultText,
               answers: [],
               correctAnswer: "",
               image: ""
            }
            newTaskData.steps.push(newStep)
            break
         }

         case TaskTypes.WORDS_NATIVE_TO_LEARNING: {
            const newStep: ISelectCorrectWordStep = {
               word: defaultText,
               answers: [],
               correctAnswer: "",
               image: ""
            }
            newTaskData.steps.push(newStep)
            break
         }

         case TaskTypes.MATCH_WORDS: {
            const newStep: IMatchWordsStep = {
               words: {}
            }
            newTaskData.steps.push(newStep)
            break
         }

         case TaskTypes.THEORY: {
            let newStep: ITheoryStep = {
               text: "",
               sentence: defaultText,
               words: [],
               correctAnswers: [],
               buttonColors: {},
               textButtons: []
            }
            newTaskData.steps.push(newStep)
            break
         }

         case TaskTypes.SENTENCE_NATIVE_TO_LEARNING: {
            let newStep: ICollectSentenceFromWordsStep = {
               sentence: defaultText,
               words: [],
               correctAnswers: []
            }
            newTaskData.steps.push(newStep)
            break
         }
         case TaskTypes.SENTENCE_LEARNING_TO_NATIVE: {
            let newStep: ICollectSentenceFromWordsStep = {
               sentence: defaultText,
               words: [],
               correctAnswers: []
            }
            newTaskData.steps.push(newStep)
            break
         }

         case TaskTypes.COLLECT_FROM_AUDIO: {
            let newStep: ICollectSentenceFromAudioStep = {
               sentence: defaultText,
               words: [],
               translation: ''
            }
            newTaskData.steps.push(newStep)
            break
         }

         case TaskTypes.TRANSLATE_FROM_AUDIO: {
            let newStep: ITranslateSentenceFromAudioStep = {
               sentence: defaultText,
               words: [],
               correctAnswers: []
            }
            newTaskData.steps.push(newStep)
            break
         }

         default:
            console.log("default")
            return
      }

      setTaskData(newTaskData)
   }

   const handleDeleteStep = (stepIndex: number) => {
      console.log("Deleting the step with the index", stepIndex)

      const newTaskData = { ...taskData }

      newTaskData.steps.splice(stepIndex, 1)

      setTaskData(newTaskData)

      saveTask(newTaskData.number, newTaskData)
   }

   const handleCopyTheoryStep = (step: ITheoryStep) => {
      const newTaskData = { ...taskData }

      const newStep: ITheoryStep = {
         ...step,
         sentence: step.sentence + Date.now(),
         buttonColors: {},
         words: [],
         correctAnswers: []
      }

      newTaskData.steps.push(newStep)

      setTaskData(newTaskData)
   }


   const handleFillFromRawData = async () => {

      const newTaskData = { ...taskData }

      let steps = [] as any[]

      setFillFromRawDataLoading(true)

      switch (newTaskData.type) {
         case TaskTypes.WORDS_NATIVE_TO_LEARNING:
            steps = await transformWordsNativeToLearning(currentLesson.data)
            break
         case TaskTypes.WORDS_LEARNING_TO_NATIVE:
            steps = await transformWordsLearningToNative(currentLesson.data)
            break
         case TaskTypes.MATCH_WORDS:
            steps = await transformMatchWords(currentLesson.data)
            break
         case TaskTypes.THEORY:
            steps = await transformTheory(currentLesson.data)
            break
         case TaskTypes.SENTENCE_NATIVE_TO_LEARNING:
            steps = await transformSentenceNativeToLearning(currentLesson.data)
            break
         case TaskTypes.SENTENCE_LEARNING_TO_NATIVE:
            steps = await transformSentenceLearningToNative(currentLesson.data)
            break
         case TaskTypes.COLLECT_FROM_AUDIO:
            steps = await transformCollectFromAudio(currentLesson.data)
            break
         case TaskTypes.TRANSLATE_FROM_AUDIO:
            steps = await transformTranslateFromAudio(currentLesson.data)
            break
      }

      setFillFromRawDataLoading(false)

      const filledNewData = { ...newTaskData, steps: [...newTaskData.steps, ...steps] }

      setTaskData(filledNewData)

      saveTask(newTaskData.number, filledNewData)
   }

   return (
      <div className="border border-primary border-2 p-4 m-3">
         <h3>
            {taskData.number}: {taskData.type}
            <button
               className="btn btn-warning ms-3"
               type="button"
               disabled={fillFromRawDataLoading}
               onClick={handleFillFromRawData}
            >
               {fillFromRawDataLoading &&
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />}
               Fill from raw data
            </button>
         </h3>


         {/*{JSON.stringify(taskData)}*/}

         {taskData &&
            taskData.steps.map((step: any, index: number) => {
               switch (taskData.type) {
                  case TaskTypes.WORDS_LEARNING_TO_NATIVE:
                     return (
                        <EditSelectCorrectWordStep
                           step={step}
                           taskType={taskData.type}
                           stepIndex={index}
                           taskNumber={taskNumber}
                           saveStep={saveStep}
                           deleteStep={handleDeleteStep}
                           key={taskNumber + index + step.word}
                        />
                     )
                  case TaskTypes.WORDS_NATIVE_TO_LEARNING:
                     return (
                        <EditSelectCorrectWordStep
                           step={step}
                           taskType={taskData.type}
                           stepIndex={index}
                           taskNumber={taskNumber}
                           saveStep={saveStep}
                           deleteStep={handleDeleteStep}
                           key={taskNumber + index + step.word}
                        />
                     )

                  case TaskTypes.MATCH_WORDS:
                     return (
                        <EditMatchWordsStep
                           step={step}
                           stepIndex={index}
                           saveStep={saveStep}
                           key={taskNumber + index}
                        />
                     )

                  case TaskTypes.THEORY:
                     return (
                        <EditTheoryStep
                           onCopyStep={() => handleCopyTheoryStep(step)}
                           step={step}
                           stepIndex={index}
                           taskNumber={taskNumber}
                           saveStep={saveStep}
                           deleteStep={handleDeleteStep}
                           key={taskNumber + index + step.sentence}
                        />
                     )

                  case TaskTypes.SENTENCE_LEARNING_TO_NATIVE:
                     return (
                        <EditCollectSentenceFromWordsStep
                           step={step}
                           stepIndex={index}
                           taskNumber={taskNumber}
                           saveStep={saveStep}
                           deleteStep={handleDeleteStep}
                           key={taskNumber + index + step.sentence}
                        />
                     )

                  case TaskTypes.SENTENCE_NATIVE_TO_LEARNING:
                     return (
                        <EditCollectSentenceFromWordsStep
                           step={step}
                           stepIndex={index}
                           taskNumber={taskNumber}
                           saveStep={saveStep}
                           deleteStep={handleDeleteStep}
                           key={taskNumber + index + step.sentence}
                        />
                     )

                  case TaskTypes.COLLECT_FROM_AUDIO:
                     return (
                        <EditCollectSentenceFromAudioStep
                           step={step}
                           stepIndex={index}
                           saveStep={saveStep}
                           key={taskNumber + index + step.sentence}
                        />
                     )

                  case TaskTypes.TRANSLATE_FROM_AUDIO:
                     return (
                        <EditTranslateSentenceFromAudioStep
                           step={step}
                           stepIndex={index}
                           saveStep={saveStep}
                           deleteStep={handleDeleteStep}
                           key={taskNumber + index + step.sentence}
                        />
                     )

                  default:
                     return <h1 key={Math.random()}>Unsupported</h1>
               }
            })}

         <button className="btn btn-warning my-2" onClick={addStep}>
            Add step
         </button>
      </div>
   )
}

export default EditTask
