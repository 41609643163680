import { translate } from "./translation"
import {
   ICollectSentenceFromAudioStep,
   ICollectSentenceFromWordsStep,
   IMatchWordsStep,
   ITheoryStep,
   ITranslateSentenceFromAudioStep
} from "../types/lesson/level/task/step/TaskStep"
import _ from "lodash"

export const transformWordsNativeToLearning = async (data: any[]) => {
   const items = data.filter(item => item.type === "choose")

   const finalData = [] as any[]

   for (const item of items) {
      const chooseItem = item.choose

      const translatedText = await translate(chooseItem.correct)

      finalData.push({
         word: translatedText,
         correctAnswer: chooseItem.correct,
         answers: _.shuffle([...chooseItem.incorrect, chooseItem.correct])
      })
   }

   return finalData
}

export const transformWordsLearningToNative = async (data: any[]) => {
   const items = data.filter(item => item.type === "reversedChoose")

   const finalData = [] as any[]

   for (const item of items) {
      const chooseItem = item.reversedChoose

      const translatedIncorrectWords = [] as string[]

      const translatedCorrect = await translate(chooseItem.correct)

      for (const word of chooseItem.incorrect) {
         const translatedInCorrectWord = await translate(word)
         translatedIncorrectWords.push(translatedInCorrectWord)
      }


      finalData.push({
         word: chooseItem.text,
         correctAnswer: translatedCorrect,
         answers: _.shuffle([...translatedIncorrectWords, translatedCorrect])
      })
   }

   return finalData
}


export const transformMatchWords = async (data: any[]) => {
   const items = data.filter(item => item.type === "match")

   const finalData = [] as any[]

   for (const item of items) {
      const matchItemArray = item.match

      const finalItem = {
         words: {}
      } as IMatchWordsStep

      for (const matchItem of matchItemArray) {
         finalItem.words[matchItem.key] = await translate(matchItem.key)
      }

      finalData.push(finalItem)
   }

   return finalData
}

export const transformTheory = async (data: any[]) => {
   const items = data.filter(item => item.type === "assembleTheory")

   const finalData = [] as any[]

   for (const item of items) {
      const theoryItem = item.assembleTheory

      const buttonColors = {}

      theoryItem.answerComponents
         .filter(item => item.color !== "#FFFFFF")
         .forEach(item => {
            buttonColors[item.text] = item.color
         })

      const sentence = await translate(theoryItem.sourceText)

      const answerWords = theoryItem.sourceText.split(" ") as string[]
      const randomWords = theoryItem.answerComponents.filter(item => {
         return item.color === "#FFFFFF" && !answerWords.includes(item.text)
      })


      const finalItem = {
         text: theoryItem.theoryText,
         correctAnswers: [theoryItem.sourceText],
         words: _.shuffle(
            [
               ...answerWords,
               ...randomWords
            ]),
         sentence,
         textButtons: theoryItem.theoryComponents.map(item => {
            const newItem = { ...item }
            if (newItem.color === "#FFFFFF") {
               newItem.color = ""
            }
            return newItem
         }),
         buttonColors
      } as ITheoryStep

      finalData.push(finalItem)
   }

   return finalData
}


export const transformSentenceNativeToLearning = async (data: any[]) => {
   const items = data.filter(item => (item.type === "assemble"))

   const finalData = [] as any[]

   for (const [index, item] of items.entries()) {

      if (index === 9) {
         break
      }

      const assembleItem = item.assemble

      const sentence = await translate(assembleItem.answers[0].text)

      const finalItem = {
         sentence,
         words: _.shuffle([...assembleItem.answers[0].text.split(" "), ...assembleItem.randomWords]),
         correctAnswers: assembleItem.answers.map(answer => answer.text)
      } as ICollectSentenceFromWordsStep

      finalData.push(finalItem)
   }

   return finalData
}

export const transformSentenceLearningToNative = async (data: any[]) => {
   const items = data.filter(item => (item.type === "reversedAssemble"))

   const finalData = [] as any[]

   for (const [index, { reversedAssemble }] of items.entries()) {

      if (index === 9) {
         break
      }

      const correctAnswer = await translate(reversedAssemble.sentence)

      const words = [] as string[]
      for (const word of [...reversedAssemble.randomWords]) {
         words.push(await translate(word))
      }


      const finalItem = {
         sentence: reversedAssemble.sentence,
         words: _.shuffle([...words, ...correctAnswer.split(" ")]),
         correctAnswers: [correctAnswer]
      } as ICollectSentenceFromWordsStep

      finalData.push(finalItem)
   }

   return finalData
}


export const transformCollectFromAudio = async (data: any[]) => {
   const items = data.filter(item => (item.type === "assembleAudio" && !item.assembleAudio.translateAudio))

   const finalData = [] as any[]

   for (const item of items) {
      const audioItem = item.assembleAudio

      const translation = await translate(audioItem.text)

      const finalItem = {
         sentence: audioItem.text,
         words: _.shuffle([...audioItem.randomWords, ...audioItem.text.split(" ")]),
         translation
      } as ICollectSentenceFromAudioStep


      finalData.push(finalItem)
   }

   return finalData
}

export const transformTranslateFromAudio = async (data: any[]) => {
   const items = data.filter(item => (item.type === "assembleAudio" && item.assembleAudio.translateAudio))

   const finalData = [] as any[]

   for (const item of items) {
      const audioItem = item.assembleAudio

      const words = [] as string[]
      for (const word of audioItem.randomWords) {
         words.push(await translate(word))
      }

      const correctAnswers = [] as string[]
      for (const answer of audioItem.translations) {
         correctAnswers.push(await translate(answer))
      }

      const finalItem = {
         sentence: audioItem.text,
         words: _.shuffle([...correctAnswers[0].split(" "), ...words]),
         correctAnswers: [...new Set(correctAnswers)]
      } as ITranslateSentenceFromAudioStep

      finalData.push(finalItem)
   }

   return finalData
}
