import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Routes} from "../../enums/routeEnums";
import {ILesson} from "../../types/lesson/ILesson";
import {LessonsApi} from "../../api/nest/LessonsApi";
import DashboardLayout from "../../layouts/DashboardLayout";

const ShowLesson = () => {
   const {lessonId} = useParams();
   const [lesson, setLesson] = useState<ILesson>();

   useEffect(() => {
      if (!lessonId) {
         return;
      }

      LessonsApi.getLessonById(lessonId).then(lessonData => setLesson(lessonData))

   }, []);

   if (!lesson) {
      return <h1>Lesson not found</h1>;
   }

   return (
      <DashboardLayout>
         <h1 className="text-center mb-5">Lesson object - {lessonId}</h1>

         {JSON.stringify(lesson)}

         <Link
            to={`/${Routes.LESSONS}/edit/${lessonId}`}
            className="btn btn-warning"
         >
            Edit
         </Link>
      </DashboardLayout>
   );
};

export default ShowLesson;
