export const replaceSpacesFromString = (string: string) => {
   let str = string.replaceAll(" ", "")
   str = str.replaceAll("'", '')
   str = str.replaceAll(",", '')
   str = str.replaceAll("՞", '')
   str = str.replaceAll("?", '')
   str = str.replaceAll(":", '')
   str = str.replaceAll("։", '')
   str = str.replaceAll("!", '')
   str = str.replaceAll(".", '')

   return str
}
