import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import {Provider} from "react-redux";
import store from "./store";
import axios from "axios";

axios.interceptors.request.use(function (config) {
   const accessToken = localStorage.getItem('accessToken')

   if(accessToken) {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${accessToken}`
   }

   return config;
});

ReactDOM.render(
   <React.StrictMode>
      <BrowserRouter>
         <Provider store={store}>
            <App/>
         </Provider>
      </BrowserRouter>
   </React.StrictMode>,
   document.getElementById("root")
);
