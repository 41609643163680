import React, { FC } from "react"
import { ILesson, LessonCategory } from "../../types/lesson/ILesson"
import { Link } from "react-router-dom"
import { Routes } from "../../enums/routeEnums"
import { IParserLesson } from "../../store/modules/parser/parserTypes"

interface LessonsTableProps {
   lessons: IParserLesson[]
}

const ParsedLessonsTable: FC<LessonsTableProps> = ({
                                                lessons,
                                             }) => {

   return (
      <div>
         {/*<h3 className="text-center mt-5 mb-2">{category}</h3>*/}

         <table className="table table-striped table-bordered text-center">
            <thead>
            <tr>
               <th scope="col">ID</th>
               <th scope="col">Level Number</th>
               <th scope="col">Level title</th>
               <th scope="col">Number</th>
               <th scope="col">Description</th>
               <th scope="col">Status</th>
               <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            {lessons.map((lesson) => (
               <tr
                  key={lesson.id}
                  className="my-5 border border-warning align-middle"
               >
                  <th>{lesson.id}</th>
                  <th>{lesson.level}</th>
                  <th>{lesson.tag}</th>
                  <td>{lesson.title}</td>
                  <td>{lesson.description}</td>
                  <td>{lesson.status}</td>

                  <td>
                     <Link
                        className="btn btn-success"
                        to={`/parser/lessons/show/${lesson.id}`}
                     >
                        Show
                     </Link>
                  </td>
               </tr>
            ))}
            </tbody>
         </table>
      </div>
   )
}

export default ParsedLessonsTable
