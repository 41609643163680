import React from "react"
import DashboardLayout from "../../layouts/DashboardLayout"
import ParserNavbar from "./ParserNavbar"
import { useParams } from "react-router-dom"
import { useParseLessonTaskIdsMutation, useParseLessonTasksMutation } from "../../store/modules/parser/parserApi"
import { useTypedSelector } from "../../store"
import { useDispatch } from "react-redux"
import { setCurrentLesson } from "../../store/modules/parser/parserSlice"
import { saveAs } from "file-saver"

const Parser = () => {
   const { lessonId } = useParams()
   const dispatch = useDispatch()
   const [parseTaskIds, { data: taskIdsData }] = useParseLessonTaskIdsMutation()
   const [parseTasks] = useParseLessonTasksMutation()

   const { currentLesson } = useTypedSelector(state => state.parser)

   const handleParseTasks = () => {
      try {
         parseTasks(taskIdsData.map(item => item.id))
            .unwrap()
            .then(data => {
               dispatch(setCurrentLesson({
                  number: 0,
                  level: "test",
                  data
               }))
            })
      } catch (e) {
         console.log(e)
      }
   }

   const handleDownloadImages = async () => {

      const chooseItems = currentLesson.data.filter(item => item.type === "choose")
      const reversedChooseItems = currentLesson.data.filter(item => item.type === "reversedChoose")

      for (const { choose } of chooseItems) {
         const response = await fetch(choose.image)
         const blob = await response.blob()
         saveAs(blob, choose.correct + ".png")
      }

      for (const { reversedChoose } of reversedChooseItems) {
         const response = await fetch(reversedChoose.image)
         const blob = await response.blob()
         saveAs(blob, reversedChoose.text + ".png")
      }
   }


   return (
      <DashboardLayout>
         <ParserNavbar />

         <button
            className="btn btn-primary me-3"
            onClick={() => parseTaskIds(lessonId as string)}
         >
            Parse task ids - {taskIdsData?.length}
         </button>

         <button
            className="btn btn-warning me-3"
            onClick={handleParseTasks}
         >
            parse tasks
         </button>

         <button
            className="btn btn-secondary mx-3"
            type="button"
            onClick={handleDownloadImages}
         >Download images
         </button>

         <div className="my-3">
            <pre id="json">{JSON.stringify(currentLesson, null, 2)}</pre>
         </div>
      </DashboardLayout>
   )
}

export default Parser
