export const translate = async (text: string, target = 'hy') => {
   const params = new URLSearchParams({
      key: process.env.REACT_APP_TRANSLATION_API_KEY as string,
      q: text,
      target
   })

   const res = await fetch(`https://translation.googleapis.com/language/translate/v2?${params.toString()}`)
   const {data} = await res.json()
   const {translations} = data
   const [translated] = translations

   return translated.translatedText
}
