export default [
   {
      "id": 2218,
      "title": "1",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331124/1-1.png",
      "video": null,
      "description": "Построение предложений в английском языке I / We / You / They"
   },
   {
      "id": 2219,
      "title": "2",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331181/1-3.png",
      "video": null,
      "description": "Построение предложений в английском языке He / She / It"
   },
   {
      "id": 2221,
      "title": "3",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331182/1-5.png",
      "video": null,
      "description": "Артикль, простые конструкции в английском языке I have, I see"
   },
   {
      "id": 2222,
      "title": "4",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331183/1-7.png",
      "video": null,
      "description": "Артикль, простые конструкции в английском языке He, She, It"
   },
   {
      "id": 2223,
      "title": "5",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331184/1-9.png",
      "video": null,
      "description": "Конструкции want to, I’d like to"
   },
   {
      "id": 2224,
      "title": "6",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331210/1-11.png",
      "video": null,
      "description": "Простое настоящее время Present Simple, отрицание don’t"
   },
   {
      "id": 2225,
      "title": "7",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331276/1-13.png",
      "video": null,
      "description": "Простое настоящее время, отрицание, doesn’t"
   },
   {
      "id": 2226,
      "title": "8",
      "level": 1,
      "tag": "Beginner",
      "status": 96,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331317/1-15.png",
      "video": null,
      "description": "Простое настоящее время, утверждение, отрицание, более сложные предложения"
   },
   {
      "id": 2227,
      "title": "9",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331318/1-17.png",
      "video": null,
      "description": "Простое настоящее время, вопрос Do"
   },
   {
      "id": 2228,
      "title": "10",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331319/1-19.png",
      "video": null,
      "description": "Простое настоящее время, вопрос Does"
   },
   {
      "id": 2229,
      "title": "11",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331320/1-21.png",
      "video": null,
      "description": "Простое настоящее время, вопрос Do Does"
   },
   {
      "id": 2230,
      "title": "12",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331364/1-23.png",
      "video": null,
      "description": "Простое настоящее время, специальный вопрос Do, Does"
   },
   {
      "id": 2231,
      "title": "13",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331442/1-25.png",
      "video": null,
      "description": "Глагол to be: am, is, are"
   },
   {
      "id": 2232,
      "title": "14",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331443/1-27.png",
      "video": null,
      "description": "Глагол to be, краткая форма"
   },
   {
      "id": 2233,
      "title": "15",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331444/1-29.png",
      "video": null,
      "description": "Глагол to be, отрицание"
   },
   {
      "id": 2234,
      "title": "16",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331445/1-31.png",
      "video": null,
      "description": "Глагол to be, вопрос"
   },
   {
      "id": 2235,
      "title": "17",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331499/1-33.png",
      "video": null,
      "description": "Глагол to be, специальный вопрос"
   },
   {
      "id": 2236,
      "title": "18",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331578/1-35.png",
      "video": null,
      "description": "Обычный глагол и глагол to be вместе, утверждение"
   },
   {
      "id": 2237,
      "title": "19",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331579/1-37.png",
      "video": null,
      "description": "Обычный глагол и глагол to be вместе, отрицание"
   },
   {
      "id": 2238,
      "title": "20",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331580/1-39.png",
      "video": null,
      "description": "Обычный глагол и to be вместе, вопрос и специальный вопрос"
   },
   {
      "id": 2239,
      "title": "21",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331604/1-41.png",
      "video": null,
      "description": "100 глаголов английского языка в разных формах 1"
   },
   {
      "id": 2240,
      "title": "22",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331701/1-43.png",
      "video": null,
      "description": "100 глаголов английского языка в разных формах  2"
   },
   {
      "id": 2242,
      "title": "23",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331717/1-45.png",
      "video": null,
      "description": "100 глаголов английского языка в разных формах  3"
   },
   {
      "id": 2243,
      "title": "24",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331718/1-47.png",
      "video": null,
      "description": "100 глаголов английского языка в разных формах  4"
   },
   {
      "id": 2244,
      "title": "25",
      "level": 1,
      "tag": "Beginner",
      "status": 99,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331719/1-49.png",
      "video": null,
      "description": "Простое будущее время Future Simple, утверждение"
   },
   {
      "id": 2245,
      "title": "26",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331787/1-2.png",
      "video": null,
      "description": "Простое будущее время, отрицание"
   },
   {
      "id": 2246,
      "title": "27",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331849/1-4.png",
      "video": null,
      "description": "Простое будущее время, вопрос"
   },
   {
      "id": 2247,
      "title": "28",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331850/1-6.png",
      "video": null,
      "description": "Простое будущее время, специальный вопрос"
   },
   {
      "id": 2248,
      "title": "29",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331851/1-8.png",
      "video": null,
      "description": "Простое будущее время, утверждение, отрицание, вопрос"
   },
   {
      "id": 2249,
      "title": "30",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331852/1-10.png",
      "video": null,
      "description": "Простое прошедшее время Past Simple, правильные и неправильные глаголы +"
   },
   {
      "id": 2250,
      "title": "31",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331910/1-12.png",
      "video": null,
      "description": "Простое прошедшее время, отрицание didn’t"
   },
   {
      "id": 2251,
      "title": "32",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331983/1-14.png",
      "video": null,
      "description": "Простое прошедшее время, вопрос Did"
   },
   {
      "id": 2252,
      "title": "33",
      "level": 1,
      "tag": "Beginner",
      "status": 97,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331984/1-16.png",
      "video": null,
      "description": "Простое прошедшее время, вопрос Did"
   },
   {
      "id": 2253,
      "title": "34",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331985/1-18.png",
      "video": null,
      "description": "85 правильных глаголов в прошедшем времени 1"
   },
   {
      "id": 2254,
      "title": "35",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/331986/1-20.png",
      "video": null,
      "description": "85 правильных глаголов в прошедшем времени 2"
   },
   {
      "id": 2255,
      "title": "36",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332065/1-22.png",
      "video": null,
      "description": "85 правильных глаголов в прошедшем времени 3"
   },
   {
      "id": 2256,
      "title": "37",
      "level": 1,
      "tag": "Beginner",
      "status": 99,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332113/1-24.png",
      "video": null,
      "description": "60 неправильных глаголов в прошедшем времени 1"
   },
   {
      "id": 2257,
      "title": "38",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332114/1-26.png",
      "video": null,
      "description": "60 неправильных глаголов в прошедшем времени 2"
   },
   {
      "id": 2258,
      "title": "39",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332115/1-28.png",
      "video": null,
      "description": "60 неправильных глаголов в прошедшем времени 3"
   },
   {
      "id": 2259,
      "title": "40",
      "level": 1,
      "tag": "Beginner",
      "status": 99,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332133/1-30.png",
      "video": null,
      "description": "Простое прошедшее время Past Simple утверждение was were"
   },
   {
      "id": 2260,
      "title": "41",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332206/1-32.png",
      "video": null,
      "description": "Простое прошедшее время отрицание wasn’t weren’t"
   },
   {
      "id": 2261,
      "title": "42",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332254/1-34.png",
      "video": null,
      "description": "Простое прошедшее время вопрос Was / Were"
   },
   {
      "id": 2262,
      "title": "43",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332255/1-36.png",
      "video": null,
      "description": "Простое прошедшее время специальный вопрос Was / Were"
   },
   {
      "id": 2263,
      "title": "44",
      "level": 1,
      "tag": "Beginner",
      "status": 99,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332256/1-38.png",
      "video": null,
      "description": "Простое прошедшее время обычный глагол и was / were"
   },
   {
      "id": 2264,
      "title": "45",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332290/1-40.png",
      "video": null,
      "description": "150 глаголов в основных временах 1"
   },
   {
      "id": 2265,
      "title": "46",
      "level": 1,
      "tag": "Beginner",
      "status": 98,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332369/1-42.png",
      "video": null,
      "description": "150 глаголов в основных временах 2"
   },
   {
      "id": 2266,
      "title": "47",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332393/1-44.png",
      "video": null,
      "description": "150 глаголов в основных временах 3"
   },
   {
      "id": 2267,
      "title": "48",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332394/1-46.png",
      "video": null,
      "description": "150 глаголов в основных временах 4"
   },
   {
      "id": 2268,
      "title": "49",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332395/1-48.png",
      "video": null,
      "description": "150 глаголов в основных временах 5"
   },
   {
      "id": 2269,
      "title": "50",
      "level": 1,
      "tag": "Beginner",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/332396/1-50.png",
      "video": null,
      "description": "150 глаголов в основных временах 6"
   },
   {
      "id": 2271,
      "title": "51",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334635/3-1.png",
      "video": null,
      "description": "Простое настоящее время (Present Simple) более сложные вещи и важные конструкции"
   },
   {
      "id": 2116,
      "title": "52",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292421/3-3.png",
      "video": null,
      "description": "Построение словосочетаний c обычным глаголом"
   },
   {
      "id": 2117,
      "title": "53",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292422/3-5.png",
      "video": null,
      "description": "Построение словосочетаний c to be"
   },
   {
      "id": 2118,
      "title": "54",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292423/3-7.png",
      "video": null,
      "description": "Present Simple обычный глагол и глагол to be одновременно"
   },
   {
      "id": 2119,
      "title": "55",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292424/3-9.png",
      "video": null,
      "description": "Present Simple сложное подлежащее, обычный глагол"
   },
   {
      "id": 2120,
      "title": "56",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292425/3-11.png",
      "video": null,
      "description": "Present Simple сложное подлежащее, глагол to be"
   },
   {
      "id": 2121,
      "title": "57",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292426/3-13.png",
      "video": null,
      "description": "Определенный артикль the"
   },
   {
      "id": 2272,
      "title": "58",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334636/3-15.png",
      "video": null,
      "description": "Present Simple отрицание, don’t doesn’t"
   },
   {
      "id": 2123,
      "title": "59",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292429/3-17.png",
      "video": null,
      "description": "Present Simple глагол to be, am not, isn’t, aren’t"
   },
   {
      "id": 2274,
      "title": "60",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334638/3-19.png",
      "video": null,
      "description": "Present Simple отрицание, обычный глагол и глагол to be одновременно, am not, isn’t, aren’t"
   },
   {
      "id": 2125,
      "title": "61",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292431/3-21.png",
      "video": null,
      "description": "Present Simple сложное подлежащее, отрицание"
   },
   {
      "id": 2126,
      "title": "62",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292432/3-23.png",
      "video": null,
      "description": "Глагол to be сложное подлежащее"
   },
   {
      "id": 2127,
      "title": "63",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292433/3-25.png",
      "video": null,
      "description": "Вопрос с обычным глаголом Do Does"
   },
   {
      "id": 2128,
      "title": "64",
      "level": 2,
      "tag": "Elementary",
      "status": 99,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292434/3-27.png",
      "video": null,
      "description": "Вопрос с глаголом to be"
   },
   {
      "id": 2129,
      "title": "65",
      "level": 2,
      "tag": "Elementary",
      "status": 97,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292435/3-29.png",
      "video": null,
      "description": "Смешанный вопрос, обычный глагол и глагол to be"
   },
   {
      "id": 2130,
      "title": "66",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292436/3-31.png",
      "video": null,
      "description": "Вопрос, сложное подлежащее, Do Does"
   },
   {
      "id": 2131,
      "title": "67",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292437/3-33.png",
      "video": null,
      "description": "Вопрос, сложное подлежащее, глагол to be"
   },
   {
      "id": 2275,
      "title": "68",
      "level": 2,
      "tag": "Elementary",
      "status": 95,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334639/3-35.png",
      "video": null,
      "description": "Вопрос, сложное подлежащее, обычный глагол и глагол to be"
   },
   {
      "id": 2133,
      "title": "69",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292439/3-37.png",
      "video": null,
      "description": "Специальный вопрос, обычный глагол и to be вопрос"
   },
   {
      "id": 2276,
      "title": "70",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334640/3-39.png",
      "video": null,
      "description": "Сложные вопросы What, Who, Which, обычный глагол и глагол to be"
   },
   {
      "id": 2135,
      "title": "71",
      "level": 2,
      "tag": "Elementary",
      "status": 89,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292441/3-41.png",
      "video": null,
      "description": "Конструкции I like, I enjoy, fond, keen, love, hate"
   },
   {
      "id": 2277,
      "title": "72",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334641/3-43.png",
      "video": null,
      "description": "200 глаголов в Present Simple Часть 1"
   },
   {
      "id": 2137,
      "title": "73",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292443/3-45.png",
      "video": null,
      "description": "200 глаголов в Present Simple Часть 2"
   },
   {
      "id": 2278,
      "title": "74",
      "level": 2,
      "tag": "Elementary",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334642/3-47.png",
      "video": null,
      "description": "200 глаголов в Present Simple Часть 3"
   },
   {
      "id": 2279,
      "title": "75",
      "level": 2,
      "tag": "Elementary",
      "status": 23,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334643/3-49.png",
      "video": null,
      "description": "200 глаголов в Present Simple Часть 4"
   },
   {
      "id": 2140,
      "title": "76",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292446/3-2.png",
      "video": null,
      "description": "200 глаголов в Present Simple Часть 5"
   },
   {
      "id": 2141,
      "title": "77",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292447/3-4.png",
      "video": null,
      "description": "Future Simple простое будущее время, утверждение"
   },
   {
      "id": 2142,
      "title": "78",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292448/3-6.png",
      "video": null,
      "description": "Future Simple простое будущее время, отрицание"
   },
   {
      "id": 2280,
      "title": "79",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334644/3-8.png",
      "video": null,
      "description": "Future Simple простое будущее время, вопрос"
   },
   {
      "id": 2144,
      "title": "80",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292450/3-10.png",
      "video": null,
      "description": "Future Simple простое будущее время, специальный вопрос"
   },
   {
      "id": 2145,
      "title": "81",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292451/3-12.png",
      "video": null,
      "description": "Past Simple простое прошедшее время, was were, сложное подлежащее"
   },
   {
      "id": 2146,
      "title": "82",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292452/3-14.png",
      "video": null,
      "description": "Past Simple didn’t wasn’t weren’t"
   },
   {
      "id": 2147,
      "title": "83",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292453/3-16.png",
      "video": null,
      "description": "Past Simple вопрос Did Was Were"
   },
   {
      "id": 2148,
      "title": "84",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292454/3-18.png",
      "video": null,
      "description": "Past Simple специальный вопрос Did Was Were"
   },
   {
      "id": 2149,
      "title": "85",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292455/3-20.png",
      "video": null,
      "description": "Past Simple 150 правильных глаголов 1"
   },
   {
      "id": 2150,
      "title": "86",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292456/3-22.png",
      "video": null,
      "description": "Past Simple 150 правильных глаголов 2"
   },
   {
      "id": 2151,
      "title": "87",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292457/3-24.png",
      "video": null,
      "description": "Past Simple 150 правильных глаголов 3"
   },
   {
      "id": 2152,
      "title": "88",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292458/3-26.png",
      "video": null,
      "description": "Past Simple 150 правильных глаголов 4"
   },
   {
      "id": 2153,
      "title": "89",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292459/3-28.png",
      "video": null,
      "description": "Past Simple 150 правильных глаголов 5"
   },
   {
      "id": 2154,
      "title": "90",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292460/3-30.png",
      "video": null,
      "description": "Past Simple 85 неправильных глаголов 1"
   },
   {
      "id": 2155,
      "title": "91",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292461/3-32.png",
      "video": null,
      "description": "Past Simple 85 неправильных глаголов 2"
   },
   {
      "id": 2156,
      "title": "92",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292462/3-34.png",
      "video": null,
      "description": "Past Simple 85 неправильных глаголов 3"
   },
   {
      "id": 2157,
      "title": "93",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292463/3-36.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  1"
   },
   {
      "id": 2158,
      "title": "94",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292464/3-38.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  2"
   },
   {
      "id": 2159,
      "title": "95",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292465/3-40.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  3"
   },
   {
      "id": 2160,
      "title": "96",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292466/3-42.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  4"
   },
   {
      "id": 2161,
      "title": "97",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292467/3-44.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  5"
   },
   {
      "id": 2162,
      "title": "98",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292468/3-46.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  6"
   },
   {
      "id": 2163,
      "title": "99",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292469/3-48.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  7"
   },
   {
      "id": 2281,
      "title": "100",
      "level": 2,
      "tag": "Elementary",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334645/3-50.png",
      "video": null,
      "description": "270 глаголов во всех основных временах  8"
   },
   {
      "id": 1623,
      "title": "101",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278379/4-1.png",
      "video": null,
      "description": "Зависимые предлоги, прилагательные 1"
   },
   {
      "id": 1624,
      "title": "102",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278380/4-3.png",
      "video": null,
      "description": "Зависимые предлоги, прилагательные 2"
   },
   {
      "id": 1625,
      "title": "103",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278381/4-5.png",
      "video": null,
      "description": "Зависимые предлоги, глаголы 1"
   },
   {
      "id": 1626,
      "title": "104",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278382/4-7.png",
      "video": null,
      "description": "Зависимые предлоги, глаголы 2"
   },
   {
      "id": 2282,
      "title": "105",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334646/4-9.png",
      "video": null,
      "description": "Зависимые предлоги, существительные, обобщение"
   },
   {
      "id": 1628,
      "title": "106",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278384/4-11.png",
      "video": null,
      "description": "Устойчивые фразы с предлогами"
   },
   {
      "id": 1629,
      "title": "107",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278385/4-13.png",
      "video": null,
      "description": "Предлоги in at on of to 1"
   },
   {
      "id": 1630,
      "title": "108",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 93,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278386/4-15.png",
      "video": null,
      "description": "Предлоги in at on of to 2"
   },
   {
      "id": 1631,
      "title": "109",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 98,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278387/4-17.png",
      "video": null,
      "description": "Фразовые глаголы, предлоги"
   },
   {
      "id": 1632,
      "title": "110",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278388/4-19.png",
      "video": null,
      "description": "Present Continuous, настоящее продолженное время,  утверждение"
   },
   {
      "id": 1633,
      "title": "111",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278389/4-21.png",
      "video": null,
      "description": "Present Continuous, настоящее продолженное время,  отрицание"
   },
   {
      "id": 1634,
      "title": "112",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278390/4-23.png",
      "video": null,
      "description": "Present Continuous, настоящее продолженное время,  общий и специальный вопрос"
   },
   {
      "id": 1635,
      "title": "113",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 94,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278391/4-27.png",
      "video": null,
      "description": "Present Simple Present Continuous разница"
   },
   {
      "id": 1636,
      "title": "114",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278015/4-27.jpg",
      "video": null,
      "description": "Глаголы состояние, state verbs"
   },
   {
      "id": 1637,
      "title": "115",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278392/4-29.png",
      "video": null,
      "description": "Past Continuous, прошедшее продолженное время"
   },
   {
      "id": 2283,
      "title": "116",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334647/4-31.png",
      "video": null,
      "description": "Present Perfect, настоящее совершенное время"
   },
   {
      "id": 1639,
      "title": "117",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 98,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278396/4-33.png",
      "video": null,
      "description": "Present Perfect Past Simple, разница"
   },
   {
      "id": 1640,
      "title": "118",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278395/4-35.png",
      "video": null,
      "description": "Present Perfect Continuous, настоящее совершенное время"
   },
   {
      "id": 1641,
      "title": "119",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278397/4-37.png",
      "video": null,
      "description": "Past Perfect, предпрошедшее время"
   },
   {
      "id": 1642,
      "title": "120",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278398/4-39.png",
      "video": null,
      "description": "Будущие времена: Future Continuous, Future Simple"
   },
   {
      "id": 1643,
      "title": "121",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278399/4-41.png",
      "video": null,
      "description": "Первое условное наклонение, First Conditional if when"
   },
   {
      "id": 1644,
      "title": "122",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 99,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278400/4-43.png",
      "video": null,
      "description": "Пассивный залог, конструкции в пассивном залоге"
   },
   {
      "id": 1645,
      "title": "123",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278401/4-45.png",
      "video": null,
      "description": "Пассивный и активный залог"
   },
   {
      "id": 1646,
      "title": "124",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278402/4-47.png",
      "video": null,
      "description": "8 основных времен, сравнение 1"
   },
   {
      "id": 1647,
      "title": "125",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 93,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278403/4-49.png",
      "video": null,
      "description": "8 основных времен, сравнение 2"
   },
   {
      "id": 1648,
      "title": "126",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 91,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278404/4-2.png",
      "video": null,
      "description": "Конструкция going to в разных временах и формах"
   },
   {
      "id": 1649,
      "title": "127",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278405/4-4.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 1"
   },
   {
      "id": 1650,
      "title": "128",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278406/4-6.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 2"
   },
   {
      "id": 1651,
      "title": "129",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278408/4-8.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 3"
   },
   {
      "id": 1652,
      "title": "130",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 99,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278409/4-10.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 4"
   },
   {
      "id": 1653,
      "title": "131",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278410/4-12.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 5"
   },
   {
      "id": 1654,
      "title": "132",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278411/4-14.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 6"
   },
   {
      "id": 1655,
      "title": "133",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278412/4-16.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 7"
   },
   {
      "id": 1656,
      "title": "134",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278413/4-18.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 8"
   },
   {
      "id": 1657,
      "title": "135",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278414/4-20.png",
      "video": null,
      "description": "360 глаголов в 8 разных временах 9"
   },
   {
      "id": 1658,
      "title": "136",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278415/4-22.png",
      "video": null,
      "description": "Сравнительная и превосходная степень прилагательных 1"
   },
   {
      "id": 1659,
      "title": "137",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278416/4-24.png",
      "video": null,
      "description": "Сравнительная и превосходная степень прилагательных 2"
   },
   {
      "id": 1660,
      "title": "138",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278417/4-26.png",
      "video": null,
      "description": "Грамматические конструкции, such so much more few little"
   },
   {
      "id": 1661,
      "title": "139",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278418/4-28.png",
      "video": null,
      "description": "230 прилагательных с разными временами и конструкциями 1"
   },
   {
      "id": 1662,
      "title": "140",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278419/4-30.png",
      "video": null,
      "description": "230 прилагательных с разными временами и конструкциями 2"
   },
   {
      "id": 1663,
      "title": "141",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 6,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278420/4-32.png",
      "video": null,
      "description": "230 прилагательных с разными временами и конструкциями 3"
   },
   {
      "id": 1664,
      "title": "142",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278421/4-34.png",
      "video": null,
      "description": "230 прилагательных с разными временами и конструкциями 4"
   },
   {
      "id": 1665,
      "title": "143",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278422/4-36.png",
      "video": null,
      "description": "230 прилагательных с разными временами и конструкциями 5"
   },
   {
      "id": 1666,
      "title": "144",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278423/4-38.png",
      "video": null,
      "description": "230 прилагательных с разными временами и конструкциями 6"
   },
   {
      "id": 1667,
      "title": "145",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278424/4-40.png",
      "video": null,
      "description": "Модальные глаголы"
   },
   {
      "id": 1668,
      "title": "146",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278425/4-42.png",
      "video": null,
      "description": "Артикли, более сложные грамматические конструкции"
   },
   {
      "id": 1669,
      "title": "147",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278426/4-44.png",
      "video": null,
      "description": "Have got / Have"
   },
   {
      "id": 2284,
      "title": "148",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334648/4-46.png",
      "video": null,
      "description": "Вопросы в отрицании"
   },
   {
      "id": 2285,
      "title": "149",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334649/4-48.png",
      "video": null,
      "description": "Разделительные вопросы, tag questions"
   },
   {
      "id": 2286,
      "title": "150",
      "level": 3,
      "tag": "Pre-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334650/4-50.png",
      "video": null,
      "description": "Важные грамматические конструкции"
   },
   {
      "id": 1673,
      "title": "151",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278481/6-1.png",
      "video": null,
      "description": "Зависимые предлоги, прилагательные 1"
   },
   {
      "id": 1674,
      "title": "152",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278482/6-3.png",
      "video": null,
      "description": "Зависимые предлоги, прилагательные 2"
   },
   {
      "id": 1675,
      "title": "153",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278483/6-5.png",
      "video": null,
      "description": "Зависимые предлоги, прилагательные 3"
   },
   {
      "id": 1676,
      "title": "154",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278484/6-7.png",
      "video": null,
      "description": "Зависимые предлоги, глаголы"
   },
   {
      "id": 2287,
      "title": "155",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334651/6-9.png",
      "video": null,
      "description": "Зависимые предлоги, существительные, обобщение"
   },
   {
      "id": 1678,
      "title": "156",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278486/6-11.png",
      "video": null,
      "description": "Устойчивые фразы с предлогами 1"
   },
   {
      "id": 1679,
      "title": "157",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278487/6-13.png",
      "video": null,
      "description": "Устойчивые фразы с предлогами 2"
   },
   {
      "id": 1680,
      "title": "158",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278488/6-15.png",
      "video": null,
      "description": "Сравнение времен 1 Present Simple Present Continuous, грамматические конструкции"
   },
   {
      "id": 1681,
      "title": "159",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278489/6-17.png",
      "video": null,
      "description": "Сравнение времен 2 Past Continuous Past Simple"
   },
   {
      "id": 2288,
      "title": "160",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334652/6-19.png",
      "video": null,
      "description": "Сравнение времен 3 Present Perfect Past Simple"
   },
   {
      "id": 1683,
      "title": "161",
      "level": 4,
      "tag": "Intermediate",
      "status": 100,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278491/6-21.png",
      "video": null,
      "description": "Сравнение времен 4 Present Perfect Continuous, Present Perfect, Past Continuous, Present Simple"
   },
   {
      "id": 1684,
      "title": "162",
      "level": 4,
      "tag": "Intermediate",
      "status": 34,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278492/6-23.png",
      "video": null,
      "description": "Сравнение времен 5 Past Perfect, Past Perfect Continuous, Past Simple, Past Continuous"
   },
   {
      "id": 1685,
      "title": "163",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278493/6-25.png",
      "video": null,
      "description": "Сравнение времен Future Simple, Future Perfect, Future Perfect Continuous"
   },
   {
      "id": 1686,
      "title": "164",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278494/6-27.png",
      "video": null,
      "description": "Пассивный залог в основных временах"
   },
   {
      "id": 1687,
      "title": "165",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278495/6-29.png",
      "video": null,
      "description": "Пассивный залог в более сложных временах"
   },
   {
      "id": 2289,
      "title": "166",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334653/6-31.png",
      "video": null,
      "description": "Активный и пассивный залог"
   },
   {
      "id": 1689,
      "title": "167",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278497/6-33.png",
      "video": null,
      "description": "12 времен в английском языке 1"
   },
   {
      "id": 1690,
      "title": "168",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278498/6-35.png",
      "video": null,
      "description": "12 времен в английском языке 2"
   },
   {
      "id": 1691,
      "title": "169",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278499/6-37.png",
      "video": null,
      "description": "12 времен в английском языке 3"
   },
   {
      "id": 1692,
      "title": "170",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278500/6-39.png",
      "video": null,
      "description": "Conditional 0, 1, условное наклонение, нулевой и первый тип"
   },
   {
      "id": 1693,
      "title": "171",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278501/6-41.png",
      "video": null,
      "description": "Conditional 2, условное наклонение, второй тип"
   },
   {
      "id": 2290,
      "title": "172",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334654/6-43.png",
      "video": null,
      "description": "Conditional  1 2 условное наклонение, первый и второй тип"
   },
   {
      "id": 1695,
      "title": "173",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278503/6-45.png",
      "video": null,
      "description": "Reported Speech, Indirect question, косвенная речь, косвенный вопрос"
   },
   {
      "id": 1696,
      "title": "174",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278504/6-47.png",
      "video": null,
      "description": "Indirect question  косвенный вопрос 2, Future in the Past, будущее в прошедшем времени"
   },
   {
      "id": 1697,
      "title": "175",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278505/6-49.png",
      "video": null,
      "description": "to / ing 1"
   },
   {
      "id": 2291,
      "title": "176",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334655/6-2.png",
      "video": null,
      "description": "to / ing 2"
   },
   {
      "id": 2292,
      "title": "177",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334656/6-4.png",
      "video": null,
      "description": "to / ing 3 обобщение"
   },
   {
      "id": 2293,
      "title": "178",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334657/6-6.png",
      "video": null,
      "description": "Инфинитив и герундий, более сложные вещи 1"
   },
   {
      "id": 2308,
      "title": "179",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/339067/6-8.png",
      "video": null,
      "description": "Инфинитив и герундий, более сложные вещи 2"
   },
   {
      "id": 2294,
      "title": "180",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334775/6-10.png",
      "video": null,
      "description": "Сравнительные конструкции 1"
   },
   {
      "id": 2295,
      "title": "181",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334820/6-12.png",
      "video": null,
      "description": "Сравнительные конструкции 2"
   },
   {
      "id": 1704,
      "title": "182",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278512/6-14.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 1"
   },
   {
      "id": 1928,
      "title": "183",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/292106/6-16.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 2"
   },
   {
      "id": 1706,
      "title": "184",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278514/6-18.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 3"
   },
   {
      "id": 1707,
      "title": "185",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278515/6-20.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 4"
   },
   {
      "id": 1708,
      "title": "186",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278516/6-22.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 5"
   },
   {
      "id": 1709,
      "title": "187",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278517/6-24.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 6"
   },
   {
      "id": 1710,
      "title": "188",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278518/6-26.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 7"
   },
   {
      "id": 1711,
      "title": "189",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278519/6-28.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 8"
   },
   {
      "id": 1712,
      "title": "190",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278520/6-30.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 9"
   },
   {
      "id": 1713,
      "title": "191",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278521/6-32.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 10"
   },
   {
      "id": 2296,
      "title": "192",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334821/6-34.png",
      "video": null,
      "description": "450 прилагательных с разными временами и конструкциями 11"
   },
   {
      "id": 1715,
      "title": "193",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278523/6-36.png",
      "video": null,
      "description": "Make do 1"
   },
   {
      "id": 1716,
      "title": "194",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278524/6-38.png",
      "video": null,
      "description": "Make do 2"
   },
   {
      "id": 1717,
      "title": "195",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278525/6-40.png",
      "video": null,
      "description": "Более сложные грамматические конструкции 1"
   },
   {
      "id": 1718,
      "title": "196",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278526/6-42.png",
      "video": null,
      "description": "Более сложные грамматические конструкции 2"
   },
   {
      "id": 1719,
      "title": "197",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278527/6-44.png",
      "video": null,
      "description": "Наречия"
   },
   {
      "id": 1720,
      "title": "198",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278528/6-46.png",
      "video": null,
      "description": "Наречия и прилагательные 1"
   },
   {
      "id": 1721,
      "title": "199",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278529/6-48.png",
      "video": null,
      "description": "Наречия и прилагательные 2"
   },
   {
      "id": 1722,
      "title": "200",
      "level": 4,
      "tag": "Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278530/6-50.png",
      "video": null,
      "description": "Словообразование"
   },
   {
      "id": 2297,
      "title": "201",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 13,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334822/5-1.png",
      "video": null,
      "description": "150 фразовых глаголов 1"
   },
   {
      "id": 1826,
      "title": "202",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278665/5-3.png",
      "video": null,
      "description": "150 фразовых глаголов 2"
   },
   {
      "id": 1827,
      "title": "203",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278666/5-5.png",
      "video": null,
      "description": "150 фразовых глаголов 3"
   },
   {
      "id": 1875,
      "title": "204",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/282575/5-7.png",
      "video": null,
      "description": "150 фразовых глаголов 4"
   },
   {
      "id": 1829,
      "title": "205",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278668/5-9.png",
      "video": null,
      "description": "150 фразовых глаголов 5"
   },
   {
      "id": 1830,
      "title": "206",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278669/5-11.png",
      "video": null,
      "description": "150 фразовых глаголов 6"
   },
   {
      "id": 1831,
      "title": "207",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278670/5-13.png",
      "video": null,
      "description": "150 фразовых глаголов 7"
   },
   {
      "id": 2298,
      "title": "208",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334823/5-15.png",
      "video": null,
      "description": "320 зависимых предлогов 1"
   },
   {
      "id": 1833,
      "title": "209",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278672/5-17.png",
      "video": null,
      "description": "320 зависимых предлогов 2"
   },
   {
      "id": 1834,
      "title": "210",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278673/5-19.png",
      "video": null,
      "description": "320 зависимых предлогов 3"
   },
   {
      "id": 1835,
      "title": "211",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278674/5-21.png",
      "video": null,
      "description": "320 зависимых предлогов 4"
   },
   {
      "id": 1836,
      "title": "212",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278675/5-23.png",
      "video": null,
      "description": "320 зависимых предлогов 5"
   },
   {
      "id": 1837,
      "title": "213",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278676/5-25.png",
      "video": null,
      "description": "320 зависимых предлогов 6"
   },
   {
      "id": 1838,
      "title": "214",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278677/5-27.png",
      "video": null,
      "description": "320 зависимых предлогов 7"
   },
   {
      "id": 1839,
      "title": "215",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278678/5-29.png",
      "video": null,
      "description": "320 зависимых предлогов 8"
   },
   {
      "id": 1840,
      "title": "216",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278679/5-31.png",
      "video": null,
      "description": "320 зависимых предлогов 9"
   },
   {
      "id": 1841,
      "title": "217",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278680/5-33.png",
      "video": null,
      "description": "320 зависимых предлогов 10"
   },
   {
      "id": 1842,
      "title": "218",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278681/5-35.png",
      "video": null,
      "description": "320 зависимых предлогов 11"
   },
   {
      "id": 1843,
      "title": "219",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278682/5-37.png",
      "video": null,
      "description": "320 зависимых предлогов 12"
   },
   {
      "id": 1844,
      "title": "220",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278683/5-39.png",
      "video": null,
      "description": "320 зависимых предлогов 13"
   },
   {
      "id": 1845,
      "title": "221",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278684/5-41.png",
      "video": null,
      "description": "320 зависимых предлогов 14"
   },
   {
      "id": 1846,
      "title": "222",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278685/5-43.png",
      "video": null,
      "description": "320 зависимых предлогов 15"
   },
   {
      "id": 1847,
      "title": "223",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278686/5-45.png",
      "video": null,
      "description": "Модальные глаголы в прошедшем времени"
   },
   {
      "id": 1848,
      "title": "224",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278687/5-47.png",
      "video": null,
      "description": "Модальные глаголы в пассивном залоге"
   },
   {
      "id": 1849,
      "title": "225",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278688/5-49.png",
      "video": null,
      "description": "Учимся говорить как носители языка 1"
   },
   {
      "id": 1850,
      "title": "226",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278689/5-2.png",
      "video": null,
      "description": "Учимся говорить как носители языка 2"
   },
   {
      "id": 1851,
      "title": "227",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278690/5-4.png",
      "video": null,
      "description": "Учимся говорить как носители языка 3"
   },
   {
      "id": 1852,
      "title": "228",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278691/5-6.png",
      "video": null,
      "description": "Учимся говорить как носители языка 4"
   },
   {
      "id": 1853,
      "title": "229",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278692/5-8.png",
      "video": null,
      "description": "Учимся говорить как носители языка 5"
   },
   {
      "id": 1854,
      "title": "230",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278693/5-10.png",
      "video": null,
      "description": "Учимся говорить как носители языка 6"
   },
   {
      "id": 1855,
      "title": "231",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278694/5-12.png",
      "video": null,
      "description": "Учимся говорить как носители языка 7"
   },
   {
      "id": 1856,
      "title": "232",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278695/5-14.png",
      "video": null,
      "description": "Учимся говорить как носители языка 8"
   },
   {
      "id": 1857,
      "title": "233",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278696/5-16.png",
      "video": null,
      "description": "Учимся говорить как носители языка 9"
   },
   {
      "id": 1858,
      "title": "234",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278697/5-18.png",
      "video": null,
      "description": "Учимся говорить как носители языка 10"
   },
   {
      "id": 1859,
      "title": "235",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278698/5-20.png",
      "video": null,
      "description": "Учимся говорить как носители языка 11"
   },
   {
      "id": 1860,
      "title": "236",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278699/5-22.png",
      "video": null,
      "description": "Conditional  2 3 условное наклонение, второй и третий тип"
   },
   {
      "id": 2299,
      "title": "237",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334824/5-24.png",
      "video": null,
      "description": "Conditional  0 1 2 3 условное наклонение, все типы, дополнительные конструкции"
   },
   {
      "id": 1862,
      "title": "238",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278701/5-26.png",
      "video": null,
      "description": "Более сложные грамматические конструкции"
   },
   {
      "id": 1863,
      "title": "239",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278702/5-28.png",
      "video": null,
      "description": "Распространенные ошибки 1"
   },
   {
      "id": 1864,
      "title": "240",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278703/5-30.png",
      "video": null,
      "description": "Распространенные ошибки 2"
   },
   {
      "id": 2300,
      "title": "241",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334825/5-32.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 1"
   },
   {
      "id": 1866,
      "title": "242",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278705/5-34.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 2"
   },
   {
      "id": 2307,
      "title": "243",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/338918/5-36.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 3"
   },
   {
      "id": 1868,
      "title": "244",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278707/5-38.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 4"
   },
   {
      "id": 1869,
      "title": "245",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278708/5-40.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 5"
   },
   {
      "id": 1870,
      "title": "246",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278709/5-42.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 6"
   },
   {
      "id": 1871,
      "title": "247",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278710/5-44.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 7"
   },
   {
      "id": 1872,
      "title": "248",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278711/5-46.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 8"
   },
   {
      "id": 1873,
      "title": "249",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/278712/5-48.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 9"
   },
   {
      "id": 2301,
      "title": "250",
      "level": 5,
      "tag": "Upper-Intermediate",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334826/5-50.png",
      "video": null,
      "description": "655 глаголов во всех временах и конструкциях 10"
   },
   {
      "id": 1876,
      "title": "251",
      "level": 6,
      "tag": "Advanced",
      "status": 5,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285285/2-1.png",
      "video": null,
      "description": "400 фразовых глаголов 1"
   },
   {
      "id": 1877,
      "title": "252",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285286/2-3.png",
      "video": null,
      "description": "400 фразовых глаголов 2"
   },
   {
      "id": 1878,
      "title": "253",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285287/2-5.png",
      "video": null,
      "description": "400 фразовых глаголов 3"
   },
   {
      "id": 1879,
      "title": "254",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285288/2-7.png",
      "video": null,
      "description": "400 фразовых глаголов 4"
   },
   {
      "id": 1880,
      "title": "255",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285289/2-9.png",
      "video": null,
      "description": "400 фразовых глаголов 5"
   },
   {
      "id": 1881,
      "title": "256",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285290/2-11.png",
      "video": null,
      "description": "400 фразовых глаголов 6"
   },
   {
      "id": 2302,
      "title": "257",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334827/2-13.png",
      "video": null,
      "description": "400 фразовых глаголов 7"
   },
   {
      "id": 1883,
      "title": "258",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285292/2-15.png",
      "video": null,
      "description": "400 фразовых глаголов 8"
   },
   {
      "id": 2303,
      "title": "259",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334828/2-17.png",
      "video": null,
      "description": "400 фразовых глаголов 9"
   },
   {
      "id": 1885,
      "title": "260",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285294/2-19.png",
      "video": null,
      "description": "400 фразовых глаголов 10"
   },
   {
      "id": 1886,
      "title": "261",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285295/2-21.png",
      "video": null,
      "description": "400 фразовых глаголов 11"
   },
   {
      "id": 1887,
      "title": "262",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285296/2-23.png",
      "video": null,
      "description": "400 фразовых глаголов 12"
   },
   {
      "id": 1888,
      "title": "263",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285297/2-25.png",
      "video": null,
      "description": "400 фразовых глаголов 13"
   },
   {
      "id": 1889,
      "title": "264",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285298/2-27.png",
      "video": null,
      "description": "400 фразовых глаголов 14"
   },
   {
      "id": 1890,
      "title": "265",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285299/2-29.png",
      "video": null,
      "description": "400 фразовых глаголов 15"
   },
   {
      "id": 1891,
      "title": "266",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285300/2-31.png",
      "video": null,
      "description": "400 фразовых глаголов 16"
   },
   {
      "id": 1892,
      "title": "267",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285301/2-33.png",
      "video": null,
      "description": "400 фразовых глаголов 17"
   },
   {
      "id": 1893,
      "title": "268",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285302/2-35.png",
      "video": null,
      "description": "400 фразовых глаголов 18"
   },
   {
      "id": 1894,
      "title": "269",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285303/2-37.png",
      "video": null,
      "description": "400 фразовых глаголов 19"
   },
   {
      "id": 1895,
      "title": "270",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285304/2-39.png",
      "video": null,
      "description": "400 фразовых глаголов 20"
   },
   {
      "id": 1896,
      "title": "271",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285305/2-41.png",
      "video": null,
      "description": "Продвинутые грамматические конструкции 1 Пассивный залог с модальными глаголами в прошедшем времени"
   },
   {
      "id": 1897,
      "title": "272",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285306/2-43.png",
      "video": null,
      "description": "Продвинутые грамматические конструкции 2 Пассивный залог с модальными глаголами, настоящее и прошедшее время"
   },
   {
      "id": 1898,
      "title": "273",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285307/2-45.png",
      "video": null,
      "description": "Продвинутые грамматические конструкции 3 Модальные глаголы, условное наклонение, смешанный тип"
   },
   {
      "id": 1899,
      "title": "274",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285308/2-47.png",
      "video": null,
      "description": "Продвинутые грамматические конструкции 4 Инверсия"
   },
   {
      "id": 1900,
      "title": "275",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285309/2-49.png",
      "video": null,
      "description": "Продвинутые грамматические конструкции 5 Другие конструкции"
   },
   {
      "id": 1901,
      "title": "276",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285310/2-2.png",
      "video": null,
      "description": "Устойчивые разговорные выражения, усилительные слова 1"
   },
   {
      "id": 1902,
      "title": "277",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285311/2-4.png",
      "video": null,
      "description": "Устойчивые разговорные выражения, усилительные слова 2"
   },
   {
      "id": 1903,
      "title": "278",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285313/2-6.png",
      "video": null,
      "description": "Устойчивые разговорные выражения, усилительные слова 3"
   },
   {
      "id": 1904,
      "title": "279",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285314/2-8.png",
      "video": null,
      "description": "Устойчивые разговорные выражения, усилительные слова 4"
   },
   {
      "id": 1926,
      "title": "280",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/289846/2-10.png",
      "video": null,
      "description": "Сильные прилагательные"
   },
   {
      "id": 1906,
      "title": "281",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285316/2-12.png",
      "video": null,
      "description": "Учимся говорить как носители языка 1"
   },
   {
      "id": 1907,
      "title": "282",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285317/2-14.png",
      "video": null,
      "description": "Учимся говорить как носители языка 2"
   },
   {
      "id": 1908,
      "title": "283",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285318/2-16.png",
      "video": null,
      "description": "Учимся говорить как носители языка 3"
   },
   {
      "id": 1909,
      "title": "284",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285319/2-18.png",
      "video": null,
      "description": "Учимся говорить как носители языка 4"
   },
   {
      "id": 1910,
      "title": "285",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285320/2-20.png",
      "video": null,
      "description": "Учимся говорить как носители языка 5"
   },
   {
      "id": 1911,
      "title": "286",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285321/2-22.png",
      "video": null,
      "description": "Учимся говорить как носители языка 6"
   },
   {
      "id": 1912,
      "title": "287",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285322/2-24.png",
      "video": null,
      "description": "Учимся говорить как носители языка 7"
   },
   {
      "id": 1913,
      "title": "288",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285323/2-26.png",
      "video": null,
      "description": "Учимся говорить как носители языка 8"
   },
   {
      "id": 2304,
      "title": "289",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334829/2-28.png",
      "video": null,
      "description": "Учимся говорить как носители языка 9"
   },
   {
      "id": 1915,
      "title": "290",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285325/2-30.png",
      "video": null,
      "description": "Учимся говорить как носители языка 10"
   },
   {
      "id": 1916,
      "title": "291",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285326/2-32.png",
      "video": null,
      "description": "Учимся говорить как носители языка 11"
   },
   {
      "id": 1917,
      "title": "292",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285327/2-34.png",
      "video": null,
      "description": "Учимся говорить как носители языка 12"
   },
   {
      "id": 2305,
      "title": "293",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334830/2-36.png",
      "video": null,
      "description": "Учимся говорить как носители языка 13"
   },
   {
      "id": 1919,
      "title": "294",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285329/2-38.png",
      "video": null,
      "description": "Учимся говорить как носители языка 14"
   },
   {
      "id": 1920,
      "title": "295",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285330/2-40.png",
      "video": null,
      "description": "Учимся говорить как носители языка 15"
   },
   {
      "id": 1921,
      "title": "296",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285331/2-42.png",
      "video": null,
      "description": "Словообразование 1"
   },
   {
      "id": 2306,
      "title": "297",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/334912/2-44.png",
      "video": null,
      "description": "Словообразование 2"
   },
   {
      "id": 1923,
      "title": "298",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285333/2-46.png",
      "video": null,
      "description": "Словообразование 3"
   },
   {
      "id": 1924,
      "title": "299",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285334/2-48.png",
      "video": null,
      "description": "Идиоматические выражения 1"
   },
   {
      "id": 1925,
      "title": "300",
      "level": 6,
      "tag": "Advanced",
      "status": 0,
      "type": "lesson",
      "image": "https://api-prod-englishgalaxy.e-legion.amdemo.ru/storage/285335/2-50.png",
      "video": null,
      "description": "Идиоматические выражения 2"
   }
]
