import {
   IFirstLevel,
   ISecondLevel,
   ITheoryAndWordsLevel,
} from "./level/Level";
import {DocumentReference} from "firebase/firestore";
import {TaskType} from "./level/task/Task";

export interface ILesson {
   id: string;
   title: string;
   description: string;
   levels: {
      data: {
         theoryAndWords?: ITheoryAndWordsLevel;
         firstLevel?: IFirstLevel;
         secondLevel?: ISecondLevel;
      };
      reference: string;
   };
}

export interface ILesson {
   id: string;
   _id: string;
   title: string;
   description: string;
   status: string;
   tasks: DocumentReference;
   number: number
   category: LessonCategory
}

export enum LessonCategory {
   BEGINNER = 'beginner',
   ELEMENTARY = 'elementary',
   PRE_INTERMEDIATE = 'preIntermediate',
   INTERMEDIATE = 'intermediate',
   UPPER_INTERMEDIATE = 'upperIntermediate',
   ADVANCED = 'advanced',
}

export interface INewLesson {
   title: string;
   description: string;
   category: LessonCategory
   number: number;
}

export enum LessonStatus {
   DRAFT = "draft",
   PUBLISHED = "published",
}

export type LessonStatusType = LessonStatus.DRAFT | LessonStatus.PUBLISHED;

export type ILessonTasks = {
   data: TaskType[];
};
