import axios from "axios"
import { IReport } from "../../types/reports/IReport"

export class ReportsApi {
   static async getAllReports(params: { skip: number, limit: number, solved?: boolean }): Promise<IReport[]> {
      const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/reports`, {
         params
      })
      return data
   }

   static async makeReportSolved(reportId: string) {
      const { data } = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/reports/${reportId}`)

      return data
   }
}
