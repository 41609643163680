import React, {ChangeEvent, FormEvent, useState} from 'react';
import {useDispatch} from "react-redux";
import {login} from "../../store/modules/auth/authActions";
import {useTypedSelector} from "../../store";
import DashboardLayout from "../../layouts/DashboardLayout";
import GuestLayout from "../../layouts/GuestLayout";

const Login = () => {

   const dispatch = useDispatch()
   const {error} = useTypedSelector(state => state.auth)


   const [form, setForm] = useState({
      email: '',
      password: ''
   })

   const handleLogin = (event: FormEvent<HTMLFormElement>) => {

      console.log(form)

      event.preventDefault()

      // @ts-ignore
      dispatch(login(form))
   }

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setForm({
         ...form,
         [event.target.name]: event.target.value
      })
   }

   return (
      <GuestLayout>
         <div className="d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center mb-5">Login</h1>
            <form onSubmit={handleLogin} className="w-50">

               <div className="form-group mb-3">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input type="email" name="email"
                         onChange={handleChange}
                         className="form-control" placeholder="Enter email" required/>
               </div>
               <div className="form-group mb-3">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input type="password" name="password"
                         onChange={handleChange}
                         className="form-control" id="exampleInputPassword1" placeholder="Password" required/>
               </div>

               <p className="text-danger">{error}</p>

               <button type="submit" className="btn btn-primary">Submit</button>
            </form>
         </div>
      </GuestLayout>
   );
};

export default Login;
