import React, {ChangeEvent, FC, KeyboardEvent, useMemo, useState} from "react";
import {ITheoryStep} from "../../types/lesson/level/task/step/TaskStep";
import {replaceSpacesFromString} from "../../utils/common";
import EditButtons from "./EditButtons";
import EditButtonsArray from "./EditButtonsArray";

interface EditTheoryStepProps {
   step: ITheoryStep;
   stepIndex: number;
   saveStep: (stepIndex: number, stepData: any) => void;
   deleteStep: (stepIndex: number) => void;
   taskNumber: number
   onCopyStep: () => void
}

const EditTheoryStep: FC<EditTheoryStepProps> = ({
                                                    step,
                                                    stepIndex,
                                                    saveStep,
                                                    deleteStep,
                                                    taskNumber,
                                                    onCopyStep
                                                 }) => {
   const [stepData, setStepData] = useState(step);

   const [newWord, setNewWord] = useState("");
   const [newCorrectAnswer, setNewCorrectAnswer] = useState("");

   const [newButton, setNewButton] = useState({
      text: "",
      color: "",
   });

   const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setStepData({
         ...stepData,
         [event.target.name]: event.target.value,
      });
   };

   const deleteWordFromWords = (index: number) => {
      const newWords = [...stepData.words];

      newWords.splice(index, 1);

      setStepData({...stepData, words: newWords});
   };

   const addWord = () => {
      if (!newWord) {
         return;
      }

      setStepData({...stepData, words: [...stepData.words, newWord]});
      setNewWord("");
   };

   const editCorrectAnswer = (
      event: ChangeEvent<HTMLInputElement>,
      index: number
   ) => {
      const newStepData = {...stepData};

      newStepData.correctAnswers[index] = event.target.value;

      setStepData(newStepData);
   };
   const deleteCorrectAnswer = (correctAnswer: string) => {
      const newStepData = {...stepData};

      newStepData.correctAnswers = newStepData.correctAnswers.filter(
         (answer) => answer !== correctAnswer
      );

      setStepData(newStepData);
   };

   const addNewCorrectAnswer = () => {
      if (!newCorrectAnswer) {
         return;
      }

      const newStepData = {...stepData};

      newStepData.correctAnswers.push(newCorrectAnswer);

      setNewCorrectAnswer("");

      setStepData(newStepData);
   };

   const addWordToFirstCorrectAnswer = (word: string) => {
      const newStepData = {...stepData};

      if (!newStepData.correctAnswers[0]) {
         newStepData.correctAnswers[0] = word
      } else {
         newStepData.correctAnswers[0] += ` ${word}`;
      }

      setNewCorrectAnswer("");

      setStepData(newStepData);
   };


   const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
         addWord();
      }
   };


   const stepUniqueId = useMemo(() => {
      return `theory_${taskNumber}${replaceSpacesFromString(stepData.sentence)}`
   }, [stepData.sentence])

   return (
      <div className="border border-success border-2 p-4 mb-4">
         {/*<h3>Edit step</h3>*/}

         {/*<div>{JSON.stringify(stepData, null, 2)}</div>*/}

         <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${stepUniqueId}`}
            aria-expanded="false"
            aria-controls={`#${stepUniqueId}`}
         >
            {stepData.sentence}
         </button>

         <div className="collapse" id={stepUniqueId}>
            <div className="my-3 border border-warning p-4">
               <div className="d-flex align-items-end">
                  <div className="w-75">
                     <label className="form-label">Text</label>
                     <textarea
                        name="text"
                        className="form-control"
                        placeholder="Text"
                        rows={7}
                        defaultValue={stepData?.text}
                        onChange={handleInputChange}
                     />
                  </div>
               </div>
               <div
                  className="my-2"
               >
                  {
                     stepData?.textButtons?.map((textButton, index) => {
                        return <button
                           key={index + textButton.text}
                           className="btn me-2"
                           style={{
                              backgroundColor: textButton.color
                           }}
                        >
                           {textButton.text}
                        </button>
                     })
                  }
               </div>


               <EditButtonsArray
                  title="Text buttons"
                  onChange={buttons => {
                     setStepData({
                        ...stepData,
                        textButtons: buttons,
                     });
                  }}
                  initialButtons={stepData.textButtons}/>


               <div className="d-flex align-items-end">
                  <div className="w-75">
                     <label className="form-label">Sentence</label>
                     <input
                        type="text"
                        name="sentence"
                        className="form-control"
                        placeholder="Sentence"
                        defaultValue={stepData?.sentence}
                        onChange={handleInputChange}
                     />
                  </div>
               </div>


               <EditButtons
                  title="Button Colors"
                  onChange={buttons => {
                     setStepData({
                        ...stepData,
                        buttonColors: buttons,
                     });
                  }}
                  initialButtons={stepData.buttonColors}/>
            </div>

            <div className="mb-3 border border-warning p-4">
               <label className="form-label">Words</label>
               <div className="d-flex my-3">
                  {stepData?.words?.map((word: string, index: number) => (
                     <div
                        className="border rounded px-2 pb-2 me-3 position-relative"
                        key={index}
                     >

                        <span
                           onClick={() => deleteWordFromWords(index)}
                           className="bg-danger position-absolute"
                           style={{
                              right: -10,
                              top: -20,
                              padding: 3,
                              borderRadius: 15,
                              color: 'white'
                           }}
                        >
                           <i className="fa-solid fa-xmark"/>
                        </span>
                        <button
                           onClick={() => addWordToFirstCorrectAnswer(word)}
                           className="btn btn-primary"
                           style={{
                              backgroundColor: stepData?.buttonColors?.[word] || '#0d6efd',
                              borderColor: stepData?.buttonColors?.[word] || '#0d6efd'
                           }}
                        >
                           {word}
                        </button>

                     </div>
                  ))}
               </div>

               <div className="d-flex">
                  <input
                     type="text"
                     className="form-control w-25"
                     placeholder="New word for answers"
                     value={newWord}
                     onKeyDown={handleKeyDown}
                     onChange={(e) => setNewWord(e.target.value)}
                  />
                  <button
                     onClick={addWord}
                     disabled={!newWord}
                     className="btn btn-warning ms-3"
                  >
                     +
                  </button>
               </div>
            </div>

            <div className="mb-3 border border-warning p-4">
               <label className="form-label">Correct answers</label>
               {stepData?.correctAnswers.map(
                  (correctAnswer: string, index: number) => (
                     <div className="d-flex align-items-center"

                          key={taskNumber + 'theory' + index}>
                        <input
                           key={index}
                           type="text"
                           className="form-control w-75 my-3"
                           placeholder={`Correct answer ${index}`}
                           value={correctAnswer}
                           onChange={(event) => editCorrectAnswer(event, index)}
                        />
                        <div>
                           <button
                              onClick={() => deleteCorrectAnswer(correctAnswer)}
                              className="btn btn-danger ms-3"
                           >
                              Delete
                           </button>
                        </div>
                     </div>
                  )
               )}

               <div className="d-flex">
                  <input
                     type="text"
                     className="form-control w-75"
                     value={newCorrectAnswer}
                     placeholder="New sentence for correct answers"
                     onChange={(e) => setNewCorrectAnswer(e.target.value)}
                  />
                  <button
                     disabled={!newCorrectAnswer}
                     className="btn btn-warning ms-3"
                     onClick={addNewCorrectAnswer}
                  >
                     +
                  </button>
               </div>
            </div>

            <button
               className="btn btn-success"
               disabled={!stepData.correctAnswers.length}
               onClick={() => saveStep(stepIndex, stepData)}
            >
               Save step with index - {stepIndex}
            </button>

            <button
               className="btn btn-warning ms-3"
               onClick={onCopyStep}
            >
               Copy the previous step
            </button>

            <button
               className="btn btn-danger ms-3"
               onClick={() => deleteStep(stepIndex)}
            >
               Delete step
            </button>

         </div>
      </div>
   );
};

export default EditTheoryStep;
