import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useSelector} from 'react-redux';
import authSlice from "./modules/auth/authSlice";
import {parserApi} from "./modules/parser/parserApi";
import parserSlice from "./modules/parser/parserSlice";

const store = configureStore({
   reducer: {
      auth: authSlice.reducer,
      parser: parserSlice.reducer,
      [parserApi.reducerPath]: parserApi.reducer
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(parserApi.middleware),

});

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
