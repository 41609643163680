import React, {FC, KeyboardEvent, useEffect, useState} from 'react';

interface EditButtonsProps {
   title: string
   initialButtons?: Record<string, string>
   onChange: (buttons: Record<string, string>) => void
}

const EditButtons: FC<EditButtonsProps> = props => {
   const {title, initialButtons = {}, onChange} = props

   const [buttons, setButtons] = useState(initialButtons)

   const [newButton, setNewButton] = useState({
      text: "",
      color: "",
   });


   useEffect(() => {
      onChange(buttons)
   }, [buttons])

   const handleNewButtonChange = (
      value: string,
      type: "text" | "color"
   ) => {
      setNewButton({
         ...newButton,
         [type]: value,
      });
   };

   const addNewButton = () => {

      if (!newButton.text) {
         return;
      }

      setButtons({
         ...buttons,
         [newButton.text]: newButton.color,
      })

      setNewButton({
         ...newButton,
         text: '',
         color: '',
      });
   };

   const editButton = (text: string) => {
      setNewButton({
         ...newButton,
         text,
         color: buttons[text]
      });
   };
   const deleteButton = (text: string) => {
      const newButtons = {...buttons};

      delete newButtons[text];

      setButtons(newButtons)

   };


   const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
         // addWord();
      }
   };

   return (
      <div className="my-3 border border-warning p-2">
         <label className="form-label">{title}</label>
         <table className="table table-striped table-bordered">
            <thead>
            <tr>
               <th scope="col">#</th>
               <th scope="col">text</th>
               <th scope="col">color</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(buttons)?.map(
               (text: string, index: number) => (
                  <tr key={index}>
                     <th>{index + 1}</th>
                     <td>{text}</td>
                     <td>{buttons[text]}</td>
                     <td>
                        <button
                           onClick={() => editButton(text)}
                           className="btn btn-warning ms-2"
                        >
                           <i className="fa-solid fa-pen-to-square"/>
                        </button>
                        <button
                           onClick={() => deleteButton(text)}
                           className="btn btn-danger ms-2"
                        >
                           <i className="fa-solid fa-trash"/>
                        </button>
                     </td>
                  </tr>
               )
            )}
            </tbody>
         </table>
         <div className="d-flex">
            <input
               type="text"
               value={newButton.text}
               className="form-control w-25 me-2"
               placeholder="Button text"
               onChange={(e) => handleNewButtonChange(e.target.value, "text")}
            />

            <input
               type="text"
               value={newButton.color}
               className="form-control w-25"
               placeholder="Button color"
               onChange={(e) =>
                  handleNewButtonChange(e.target.value, "color")
               }
               onKeyDown={handleKeyDown}
            />
            <button
               disabled={!newButton.text || !newButton.color}
               className="btn btn-warning ms-2"
               onClick={addNewButton}
            >
               +
            </button>
         </div>
      </div>
   );
};

export default EditButtons;
