import React from "react";
import GuestLayout from "../layouts/GuestLayout";

const Home = () => {
   return (
      <GuestLayout>
         <h1>404</h1>
      </GuestLayout>
   );
};

export default Home;
