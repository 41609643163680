import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import EditLesson from "./pages/lessons/EditLesson"
import EditTasks from "./components/task/EditTasks"
import Lessons from "./pages/lessons/Lessons"
import Tasks from "./pages/tasks/Tasks"
import AddLesson from "./pages/lessons/AddLesson"
import ShowLesson from "./pages/lessons/ShowLesson"
import Login from "./pages/auth/Login"
import Home from "./pages/Home"
import Parser from "./pages/parser/Parser"
import ParserLesson from "./pages/parser/ParserLesson"
import ReportsList from "./pages/reports/ReportsList"

const App = () => {
   return (
      <Routes>
         <Route path="/login" element={<Login />} />
         <Route path="" element={<Lessons />} />
         <Route path="/lessons" element={<Lessons />} />
         <Route path="/lessons/edit/:lessonId" element={<EditLesson />} />
         <Route path="/lessons/show/:lessonId" element={<ShowLesson />} />
         <Route path="/lessons/add" element={<AddLesson />} />
         <Route path="/tasks" element={<Tasks />} />
         <Route path="/tasks/edit/:tasksId" element={<EditTasks />} />
         <Route path="/parser" element={<Parser />} />
         <Route path="/parser/lessons/show/:lessonId" element={<ParserLesson />} />
         <Route path="/reports" element={<ReportsList />} />
         <Route path="*" element={<Home />} />
      </Routes>
   )
}

export default App
