import React from 'react';
import {Link} from "react-router-dom";
import {logout} from "../store/modules/auth/authSlice";
import {useDispatch} from "react-redux";

const Navbar = () => {

   const dispatch = useDispatch()

   return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light mb-5">
         <div className="container-fluid">
            <Link className="navbar-brand" aria-current="page" to="/">
               Home
            </Link>

            <Link className="navbar-brand" aria-current="page" to="/parser">
               Parser
            </Link>

            <Link className="navbar-brand" aria-current="page" to="/reports">
               Reports
            </Link>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
               <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                     <button className="btn btn-danger" onClick={() => dispatch(logout())}>
                        Logout
                     </button>
                  </li>
               </ul>
            </div>
         </div>
      </nav>
   );
};

export default Navbar;
