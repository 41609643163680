import React, {FC} from "react";
import {ILesson, LessonCategory} from "../../types/lesson/ILesson";
import {Link} from "react-router-dom";
import {Routes} from "../../enums/routeEnums";

interface LessonsTableProps {
   category: LessonCategory | string
   lessons: ILesson[]
   onDeleteLesson: (lessonId: string) => void
}

const LessonsTable: FC<LessonsTableProps> = ({
                                                lessons,
                                                category,
                                                onDeleteLesson
                                             }) => {

   return (
      <div>
         <h3 className="text-center mt-5 mb-2">{category}</h3>

         <table className="table table-striped table-bordered text-center">
            <thead>
            <tr>
               <th scope="col">ID</th>
               <th scope="col">Number</th>
               <th scope="col">Title</th>
               <th scope="col">Description</th>
               <th scope="col">Status</th>
               <th scope="col">Tasks</th>
               <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            {lessons.map((lesson) => (
               <tr
                  key={lesson._id}
                  className="my-5 border border-warning align-middle"
               >
                  <th>{lesson._id}</th>
                  <th>{lesson.number}</th>
                  <td>{lesson.title}</td>
                  <td>{lesson.description}</td>
                  <td>{lesson.status}</td>
                  <td>
                     {/*<Link*/}
                     {/*   className="btn btn-success"*/}
                     {/*   to={`/${Routes.TASKS}/show/${lesson.tasks.id}`}*/}
                     {/*>*/}
                     {/*  {lesson.tasks.id}*/}
                     {/*</Link>*/}
                     <Link
                        className="btn btn-warning"
                        to={`/${Routes.TASKS}/edit/${lesson.tasks}`}
                     >
                        {lesson.tasks}
                     </Link>
                  </td>
                  <td>
                     <Link
                        className="btn btn-success"
                        to={`/${Routes.LESSONS}/show/${lesson._id}`}
                     >
                        Show
                     </Link>
                     <Link
                        className="btn btn-warning mx-2"
                        to={`/${Routes.LESSONS}/edit/${lesson._id}`}
                     >
                        Edit
                     </Link>
                     {/**/}
                     <button
                        className="btn btn-danger"
                        onClick={() => onDeleteLesson(lesson._id)}
                     >
                        Delete
                     </button>
                  </td>
               </tr>
            ))}
            </tbody>
         </table>
      </div>
   );
};

export default LessonsTable;
