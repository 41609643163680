import React, {FC, useEffect} from 'react';
import {useTypedSelector} from "../store";
import {useNavigate} from "react-router-dom";
import Navbar from "../components/Navbar";

const DashboardLayout: FC = ({children}) => {

   const navigate = useNavigate()
   const {accessToken} = useTypedSelector(state => state.auth)

   useEffect(() => {

      if (!accessToken) {
         navigate('/login')
      }
   }, [accessToken])


   return (
      <div className="container-fluid" style={{
         padding: '120px'
      }}>
         <Navbar/>
         {children}
      </div>
   );
};

export default DashboardLayout;
