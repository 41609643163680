import {
  ICollectSentenceFromAudioStep, IMatchWordsStep,
  ISelectCorrectWordStep,
  ITheoryStep,
  ITranslateSentenceFromAudioStep,
} from "./step/TaskStep";

// export type TaskType = 'words' | 'theory' | 'fromNativeToLearning' | 'fromLearningToNative' | 'collectFromAudio'

export enum TaskTypes {
  WORDS_NATIVE_TO_LEARNING = "wordsNativeToLearning",
  WORDS_LEARNING_TO_NATIVE = "wordsLearningToNative",
  MATCH_WORDS = "matchWords",
  THEORY = "theory",
  SENTENCE_NATIVE_TO_LEARNING = "sentenceNativeToLearning",
  SENTENCE_LEARNING_TO_NATIVE = "sentenceLearningToNative",
  COLLECT_FROM_AUDIO = "collectFromAudio",
  TRANSLATE_FROM_AUDIO = "translateFromAudio",
}


export interface ISelectCorrectWordTask {
  type: TaskTypes.WORDS_LEARNING_TO_NATIVE | TaskTypes.WORDS_NATIVE_TO_LEARNING;
  steps: ISelectCorrectWordStep[];
  number: number;
  headerText?: string
}

export interface IMatchWordsTask {
  type: TaskTypes.MATCH_WORDS
  steps: IMatchWordsStep[];
  number: number;
  headerText?: string
}

export interface ITheoryTask {
  type: TaskTypes.THEORY;
  steps: ITheoryStep[];
  number: number;
  headerText?: string
}

export interface ICollectSentenceFromWordsTask {
  type: TaskTypes.SENTENCE_LEARNING_TO_NATIVE | TaskTypes.SENTENCE_NATIVE_TO_LEARNING;
  steps: ITheoryStep[];
  number: number;
  headerText?: string
}

export interface ICollectSentenceFromAudioTask {
  type: TaskTypes.COLLECT_FROM_AUDIO;
  steps: ICollectSentenceFromAudioStep[];
  number: number;
  headerText?: string
}

export interface ITranslateSentenceFromAudioTask {
  type: TaskTypes.TRANSLATE_FROM_AUDIO;
  steps: ITranslateSentenceFromAudioStep[];
  number: number;
  headerText?: string
}

export type TaskType =
   | ISelectCorrectWordTask
   | ITheoryTask
   | ICollectSentenceFromWordsTask
   | ICollectSentenceFromAudioTask
   | ITranslateSentenceFromAudioTask
   | IMatchWordsTask
