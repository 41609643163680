import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { ILoginPayload, IParserLesson, IParserUser } from "./parserTypes"
import { RootState } from "../../index"

const baseQuery = fetchBaseQuery({
   baseUrl: "https://api-prod-englishgalaxy.e-legion.amdemo.ru/api/",
   prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState
      const token = state.parser.user?.token

      if (token) {
         headers.set("Authorization", `Bearer ${token}`)
      }

      return headers
   }
})

export const parserApi = createApi({
   reducerPath: "parserApi",
   baseQuery,
   endpoints: (builder) => ({
      parserLogin: builder.mutation<IParserUser, ILoginPayload>({
         query: (data) => ({
            url: "/account/login",
            method: "POST",
            body: data
         })
      }),
      parseLessons: builder.mutation<IParserLesson[], void>({
         query: () => ({
            url: "/lessons/list",
            method: "POST",
            body: {
               native: "ru",
               target: "en"
            }
         })
      }),
      parseLessonTaskIds: builder.mutation<any, number | string>({
         query: (id) => ({
            url: "/lessons/tests/list",
            method: "POST",
            body: {
               id
            }
         })
      }),
      parseLessonTasks: builder.mutation<any, string[]>({
         query: (ids) => ({
            url: "/lessons/tests/",
            method: "POST",
            body: {
               ids
            }
         })
      })
   })
})

export const { useParserLoginMutation, useParseLessonsMutation, useParseLessonTaskIdsMutation, useParseLessonTasksMutation } = parserApi
