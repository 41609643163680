import React, { FC, KeyboardEvent, useEffect, useState } from "react"
import { ColoredButton } from "../../types/lesson/level/task/step/TaskStep"


interface EditButtonsArrayProps {
   title: string
   initialButtons?: ColoredButton[]
   onChange: (buttons: ColoredButton[]) => void
}

const EditButtonsArray: FC<EditButtonsArrayProps> = props => {
   const { title, initialButtons = [], onChange } = props

   const [buttons, setButtons] = useState(initialButtons)

   const [newButton, setNewButton] = useState({
      text: "",
      color: ""
   })

   const [editButtonIndex, setEditButtonIndex] = useState<number | null>(null)

   useEffect(() => {
      onChange(buttons)
   }, [buttons])

   const handleNewButtonChange = (
      value: string,
      type: "text" | "color"
   ) => {
      setNewButton({
         ...newButton,
         [type]: value
      })
   }

   const addNewButton = () => {

      if (!newButton.text) {
         return
      }

      if (editButtonIndex !== null) {
         buttons[editButtonIndex] = { ...newButton }

         setButtons([...buttons])

      } else {
         setButtons([
            ...buttons, { ...newButton }
         ])
      }


      setNewButton({
         text: "",
         color: ""
      })
      setEditButtonIndex(null)
   }

   const editButton = (index: number) => {
      setNewButton({
         text: buttons[index]["text"],
         color: buttons[index]["color"]
      })

      setEditButtonIndex(index)
   }

   const deleteButton = (index: number) => {
      const newButtons = buttons.filter((button, bIndex) => index !== bIndex)

      setButtons(newButtons)
   }


   const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
         addNewButton();
      }
   }

   return (
      <div className="my-3 border border-warning p-2">
         <label className="form-label">{title}</label>
         <table className="table table-striped table-bordered">
            <thead>
            <tr>
               <th scope="col">#</th>
               <th scope="col">text</th>
               <th scope="col">color</th>
            </tr>
            </thead>
            <tbody>
            {buttons.map(
               (button: ColoredButton, index: number) => (
                  <tr key={index}>
                     <th>{index + 1}</th>
                     <td>{button.text}</td>
                     <td>{button.color}</td>
                     <td>
                        <button
                           onClick={() => editButton(index)}
                           className="btn btn-warning ms-2"
                        >
                           <i className="fa-solid fa-pen-to-square" />
                        </button>
                        <button
                           onClick={() => deleteButton(index)}
                           className="btn btn-danger ms-2"
                        >
                           <i className="fa-solid fa-trash" />
                        </button>
                     </td>
                  </tr>
               )
            )}
            </tbody>
         </table>
         <div className="d-flex">
            <input
               type="text"
               value={newButton.text}
               className="form-control w-25 me-2"
               placeholder="Button text"
               onChange={(e) => handleNewButtonChange(e.target.value, "text")}
               onKeyDown={handleKeyDown}
            />

            <input
               type="text"
               value={newButton.color}
               className="form-control w-25"
               placeholder="Button color"
               onChange={(e) =>
                  handleNewButtonChange(e.target.value, "color")
               }
               onKeyDown={handleKeyDown}
            />
            <button
               disabled={!newButton.text}
               className="btn btn-warning ms-2"
               onClick={addNewButton}
            >
               +
            </button>
         </div>
      </div>
   )
}

export default EditButtonsArray
