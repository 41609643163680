import React, {ChangeEvent, FC, KeyboardEvent, useMemo, useState} from "react";
import {ISelectCorrectWordStep} from "../../types/lesson/level/task/step/TaskStep";
import {S3Api} from "../../api/nest/S3Api";
import {replaceSpacesFromString} from "../../utils/common";
import {TaskTypes} from "../../types/lesson/level/task/Task";

interface EditSelectCorrectWordStepProps {
   step: ISelectCorrectWordStep;
   stepIndex: number;
   saveStep: (stepIndex: number, stepData: any) => void;
   deleteStep: (stepIndex: number) => void;
   taskNumber: number
   taskType: TaskTypes
}

const EditSelectCorrectWordStep: FC<EditSelectCorrectWordStepProps> = ({
                                                                          step,
                                                                          stepIndex,
                                                                          saveStep,
                                                                          deleteStep,
                                                                          taskNumber,
                                                                          taskType
                                                                       }) => {
   const [stepData, setStepData] = useState<ISelectCorrectWordStep>(step);
   const [newWordForAnswer, setNewWordForAnswer] = useState("");

   const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {

      setStepData({
         ...stepData,
         [event.target.name]: event.target.value,
      });

   };
   
   const setCorrectAnswer = (answer: string) => {
      setStepData({
         ...stepData,
         correctAnswer: answer,
      });
   };

   const deleteWordFromAnswers = (index: number) => {
      const newAnswers = [...stepData.answers];

      newAnswers.splice(index, 1);

      setStepData({...stepData, answers: newAnswers});
   };

   const addAnswer = () => {
      console.log("addAnswer");

      if (!newWordForAnswer) {
         return;
      }

      setStepData({
         ...stepData,
         answers: [...stepData.answers, newWordForAnswer],
      });
      setNewWordForAnswer("");
   };

   const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
         addAnswer();
      }
   };

   const stepUniqueId = useMemo(() => {
      return `select_correct_word_${replaceSpacesFromString(taskNumber + stepData.word)}`
   }, [stepData.word])

   return (
      <div className="border border-success border-2 p-4 my-4">
         <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${stepUniqueId}`}
            aria-expanded="false"
            aria-controls={`#${stepUniqueId}`}
         >
            {stepData.word}
         </button>

         <div className="collapse" id={stepUniqueId}>
            <div className="py-3">
               {/*<h3>Edit step</h3>*/}

               {/*{JSON.stringify(stepData)}*/}

               <div className="row">
                  <div className="col-lg-8">
                     <div className="mb-3 border border-warning p-4">
                        <label className="form-label">Word</label>
                        <input
                           type="text"
                           name="word"
                           className="form-control"
                           placeholder="Word"
                           defaultValue={stepData?.word}
                           onChange={handleInputChange}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4">
                        <img
                           src={process.env.REACT_APP_IMAGES_BASE_URL + (taskType === TaskTypes.WORDS_NATIVE_TO_LEARNING ? step.correctAnswer : step.word) + '.png'}
                           alt=""
                           className="mt-3"
                           style={{width: 100, height: 100}}
                        />
                  </div>
               </div>

               {/*<div className="mb-3 border border-warning p-4">*/}
               {/*   <label className="form-label">Image</label>*/}
                  {/*<div className="row">*/}
                  {/*   <div className="col-lg-8">*/}
                  {/*      <input*/}
                  {/*         type="text"*/}
                  {/*         name="image"*/}
                  {/*         className="form-control"*/}
                  {/*         placeholder="Image"*/}
                  {/*         value={stepData?.image}*/}
                  {/*         onChange={handleInputChange}*/}
                  {/*      />*/}
                  {/*   </div>*/}
                  {/*   <div className="col-lg-4">*/}
                  {/*      <input*/}
                  {/*         type="file"*/}
                  {/*         className="form-control ms-2"*/}
                  {/*         placeholder="Image file"*/}
                  {/*         onChange={handeImageFileInputChange}*/}
                  {/*      />*/}
                  {/*   </div>*/}
                  {/*</div>*/}
               {/*</div>*/}

               <div className="mb-3 border border-warning p-4">
                  <label className="form-label">Answers</label>
                  <div className="d-flex my-3">
                     {stepData?.answers?.map((answer: string, index: number) => (
                        <div
                           key={index}
                           className={`text-center me-3 rounded border p-3 ${
                              stepData.correctAnswer === answer
                                 ? "border-success bg-light"
                                 : ""
                           }`}
                        >
                           <button
                              onClick={() => deleteWordFromAnswers(index)}
                              className="btn btn-primary"
                           >
                              {answer}
                           </button>
                           <button
                              onClick={() => setCorrectAnswer(answer)}
                              className={`btn btn my-2 m-auto d-block ${
                                 stepData.correctAnswer === answer &&
                                 "bg-success text-white"
                              }`}
                           >
                              <i className="fa-solid fa-check"/>
                           </button>
                        </div>
                     ))}
                  </div>

                  <div className="d-flex">
                     <input
                        type="text"
                        value={newWordForAnswer}
                        className="form-control w-25"
                        placeholder="New word for answers"
                        onChange={(e) => setNewWordForAnswer(e.target.value)}
                        onKeyDown={handleKeyDown}
                     />
                     <button
                        disabled={!newWordForAnswer}
                        className="btn btn-warning ms-2"
                        onClick={addAnswer}
                     >
                        +
                     </button>
                  </div>
               </div>

               <div className="mb-3 border border-warning p-4">
                  <label className="form-label">Correct answer</label> -{" "}
                  <span className="fw-bold">{stepData?.correctAnswer}</span>
               </div>
            </div>

            <button
               className="btn btn-success"
               disabled={
                  !stepData.correctAnswer ||
                  !stepData.answers.length ||
                  !stepData.answers.includes(stepData.correctAnswer)
               }
               onClick={() => saveStep(stepIndex, stepData)}
            >
               Save step with index - {stepIndex}
            </button>
            <button
               className="btn btn-danger ms-3"
               onClick={() => deleteStep(stepIndex)}
            >
               Delete step
            </button>
         </div>
      </div>
   );
};

export default EditSelectCorrectWordStep;
