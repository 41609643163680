import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {ILesson} from "../../types/lesson/ILesson";
import {Link} from "react-router-dom";
import {Routes} from "../../enums/routeEnums";

interface LessonFormProps {
   saveLesson: (lessonData: any) => void;
   lesson: ILesson | any;
}

const EditLessonForm: FC<LessonFormProps> = ({
                                                lesson,
                                                saveLesson,
                                             }) => {
   const [lessonData, setLessonData] = useState<any>(lesson);

   useEffect(() => {
      setLessonData(lesson);
   }, [lesson]);


   // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
   //
   //    let value = event.target.value
   //
   //    if()
   //
   //    setLessonData({ ...lessonData, [event.target.name]: event.target.value })
   // }

   return (
      <div>
         {/*{lessonData ? (*/}
         {/*  <p>{JSON.stringify(lessonData, null, 2)}</p>*/}
         {/*) : (*/}
         {/*  <p className="my-5">Loading...</p>*/}
         {/*)}*/}

         <div className="mb-3">
            <label className="form-label">Title</label>
            <input
               type="text"
               className="form-control"
               placeholder="Title"
               defaultValue={lessonData?.title}
               onChange={(e) =>
                  setLessonData({...lessonData, title: e.target.value})
               }
            />
         </div>

         <div className="mb-3">
            <label className="form-label">Description</label>
            <input
               type="text"
               className="form-control"
               placeholder="Description"
               defaultValue={lessonData?.description}
               onChange={(e) =>
                  setLessonData({...lessonData, description: e.target.value})
               }
            />
         </div>

         <div className="mb-3">
            <label className="form-label">Number</label>
            <input
               type="number"
               className="form-control"
               placeholder="Number"
               defaultValue={lessonData?.number}
               onChange={(e) =>
                  setLessonData({...lessonData, number: Number(e.target.value)})
               }
            />
         </div>

         <div className="mb-3">
            <label className="form-label">Category</label>
            <input
               type="text"
               className="form-control"
               placeholder="Category"
               defaultValue={lessonData?.category}
               onChange={(e) =>
                  setLessonData({...lessonData, category: e.target.value})
               }
            />
         </div>

         <div className="mb-3">
            <label className="form-label">Status</label>
            <select
               value={lessonData?.status}
               className="form-select"
               onChange={e => setLessonData({...lessonData, status: e.target.value})}
            >
               <option>Select</option>
               <option value="draft">Draft</option>
               <option value="published">Published</option>
            </select>
         </div>


         <div className="mb-3">
            <label className="form-label">Tasks Reference</label>
            <input
               type="text"
               disabled
               className="form-control"
               placeholder="Description"
               defaultValue={lessonData?.tasks?._id}
            />
         </div>


         <button
            onClick={() => saveLesson(lessonData)}
            className="btn btn-primary mt-3"
         >
            Submit
         </button>
      </div>
   );
};

export default EditLessonForm;
