import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IParserCurrentLesson, IParserLesson, IParserUser } from "./parserTypes"
import lessons from "../../../utils/parsedLessons"

interface ParserInitialState {
   user?: IParserUser
   lessons: IParserLesson[],
   currentLesson: IParserCurrentLesson
}

const initialState: ParserInitialState = {
   user: JSON.parse(localStorage.getItem("parserUser") as string),
   currentLesson: JSON.parse(localStorage.getItem("currentParsedLesson") as string),
   lessons,
}

const tasksSlice = createSlice({
   name: "parser",
   initialState,
   reducers: {
      setParserUser(state, { payload }: PayloadAction<IParserUser>) {
         state.user = payload
         localStorage.setItem("parserUser", JSON.stringify(payload))
      },
      setCurrentLesson(state, { payload }: PayloadAction<IParserCurrentLesson>) {
         localStorage.setItem("currentParsedLesson", JSON.stringify(payload))
         state.currentLesson = payload
      }
   }
})

export const { setParserUser, setCurrentLesson } = tasksSlice.actions

export default tasksSlice
