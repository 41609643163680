import axios from "axios";
import {ILesson, INewLesson} from "../../types/lesson/ILesson";

export class LessonsApi {

   static async getAllLessons() {
      const {data} = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/lessons`)
      return data
   }

   static async getLessonById(lessonId: string) {
      const {data} = await axios.get<ILesson>(`${process.env.REACT_APP_API_BASE_URL}/admin/lessons/${lessonId}`)
      return data
   }

   static async createLesson(lessonData: INewLesson) {
      const {data} = await axios.post<ILesson>(`${process.env.REACT_APP_API_BASE_URL}/admin/lessons`, lessonData)
      return data
   }

   static async updateLesson(lessonId: string, lessonData: ILesson) {
      const {data} = await axios.put<ILesson>(`${process.env.REACT_APP_API_BASE_URL}/admin/lessons/${lessonId}`, lessonData)
      return data
   }
}
