import React, {useEffect, useState} from "react";
import EditLessonForm from "../../components/lesson/EditLessonForm";
import {useParams} from "react-router-dom";
import {LessonsApi} from "../../api/nest/LessonsApi";
import DashboardLayout from "../../layouts/DashboardLayout";

const EditLesson = () => {
   const {lessonId} = useParams();
   const [lesson, setLesson] = useState<any>();

   const saveLesson = (lessonData: any) => {
      // TODO: not implemented
      console.log(lessonId as string, lessonData);

      LessonsApi.updateLesson(lessonId as string, lessonData)

   };


   const getLesson = () => {
      LessonsApi.getLessonById(lessonId as string).then((lesson) => setLesson(lesson));
   };

   useEffect(() => {
      getLesson();
   }, []);

   return (
      <DashboardLayout>
         <EditLessonForm
            saveLesson={saveLesson}
            lesson={lesson}
         />
      </DashboardLayout>
   );
};

export default EditLesson;
