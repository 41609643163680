import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useMemo,
  useState,
} from "react";
import { firebaseStorage } from "../../services/firestore";
import {
  IMatchWordsStep,
  ISelectCorrectWordStep,
} from "../../types/lesson/level/task/step/TaskStep";

interface EditMatchWordsStepProps {
  step: IMatchWordsStep;
  stepIndex: number;
  saveStep: (stepIndex: number, stepData: any) => void;
}

const EditMatchWordsStep: FC<EditMatchWordsStepProps> = ({
  step,
  stepIndex,
  saveStep,
}) => {
  const [stepData, setStepData] = useState<IMatchWordsStep>(step);
  const [newAnswer, setNewAnswer] = useState({
    word: "",
    definition: "",
  });

  const handleNewAnswerChange = (
    value: string,
    type: "word" | "definition"
  ) => {
    console.log("handleNewAnswerChange");

    setNewAnswer({
      ...newAnswer,
      [type]: value,
    });
  };

  const addAnswer = () => {
    console.log("addAnswer");

    if (!newAnswer.word || !newAnswer.definition) {
      return;
    }

    setStepData({
      ...stepData,
      words: {
        ...stepData.words,
        [newAnswer.word]: newAnswer.definition,
      },
    });

    setNewAnswer({
      ...newAnswer,
      word: '',
      definition: '',
    });
  };

  const editAnswer = (word: string) => {
    console.log("deleteAnswer");

    setNewAnswer({
      ...newAnswer,
      word,
      definition: stepData.words[word],
    });
  };
  const deleteAnswer = (word: string) => {
    console.log("deleteAnswer");

    const newWords = { ...stepData.words };

    delete newWords[word];
    setStepData({
      ...stepData,
      words: newWords,
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      addAnswer();
    }
  };

  const id = useMemo(() => {
    return "matchWordsStep" + stepIndex;
  }, []);

  return (
    <div className="border border-success border-2 p-4 my-4">
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
        aria-expanded="false"
        aria-controls={`#${id}`}
      >
        {id}
      </button>

      <div className="collapse" id={`${id}`}>
        <div className="py-3">
          {/*<h3>Edit step</h3>*/}

          {/*{JSON.stringify(stepData)}*/}
          {/*{JSON.stringify(newAnswer, null, 2)}*/}

          <div className="mb-3 border border-warning p-4">
            {/*<label className="form-label">Answers</label>*/}
            <div className="my-4">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">count</th>
                    <th scope="col">word</th>
                    <th scope="col">definition</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(stepData?.words)?.map(
                    (word: string, index: number) => (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <td >{word}</td>
                        <td>{stepData.words[word]}</td>
                        <td>
                          <button
                            onClick={() => editAnswer(word)}
                            className="btn btn-warning ms-2"
                          >
                            <i className="fa-solid fa-pen-to-square"/>
                          </button>
                          <button
                             onClick={() => deleteAnswer(word)}
                             className="btn btn-danger ms-2"
                          >
                            <i className="fa-solid fa-trash"/>
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            <div className="d-flex">
              <input
                type="text"
                value={newAnswer.word}
                className="form-control w-25 me-2"
                placeholder="New word for answers"
                onChange={(e) => handleNewAnswerChange(e.target.value, "word")}
              />

              <input
                type="text"
                value={newAnswer.definition}
                className="form-control w-25"
                placeholder="New word for answers"
                onChange={(e) =>
                  handleNewAnswerChange(e.target.value, "definition")
                }
                onKeyDown={handleKeyDown}
              />
              <button
                disabled={!newAnswer.word || !newAnswer.definition}
                className="btn btn-warning ms-2"
                onClick={addAnswer}
              >
                +
              </button>
            </div>
          </div>
        </div>

        <button
           className="btn btn-success"
           disabled={
              !Object.keys(stepData.words).length
           }
           onClick={() => saveStep(stepIndex, stepData)}
        >
           Save step with index - {stepIndex}
        </button>
      </div>
    </div>
  );
};

export default EditMatchWordsStep;
