import React, {ChangeEvent, FC, useState} from "react";
import {INewLesson, LessonCategory} from "../../types/lesson/ILesson";

interface AddLessonFormProps {
   saveLesson: (lessonData: any) => void;
}

const AddLessonForm: FC<AddLessonFormProps> = ({saveLesson}) => {
   const [lessonData, setLessonData] = useState<INewLesson>({
      description: "",
      title: "",
      category: LessonCategory.BEGINNER,
      number: 0,
   });

   const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setLessonData({
         ...lessonData,
         [event.target.name]: event.target.value,
      });
   };

   return (
      <div>
         {/*{JSON.stringify(lessonData)}*/}

         <div className="mb-3">
            <label className="form-label">Title</label>
            <input
               type="text"
               name="title"
               className="form-control"
               placeholder="Title"
               defaultValue={lessonData?.title}
               onChange={handleInputChange}
            />
         </div>

         <div className="mb-3">
            <label className="form-label">Description</label>
            <input
               type="text"
               name="description"
               className="form-control"
               placeholder="Description"
               defaultValue={lessonData?.description}
               onChange={handleInputChange}
            />
         </div>

         <div className="mb-3">
            <label className="form-label">Number</label>
            <input
               type="number"
               name="number"
               className="form-control"
               placeholder="Number"
               defaultValue={lessonData?.number}
               onChange={handleInputChange}
            />
         </div>

         <div className="mb-3">
            <label className="form-label">Category</label>
            <select
               name="category"
               onChange={handleInputChange}
               className="form-select"
               value={lessonData?.category}
               aria-label="Default select example">
               {
                  Object.keys(LessonCategory).map(category => <option key={category}
                                                                      value={LessonCategory[category]}>{category}</option>)
               }
            </select>
         </div>

         <button
            disabled={!lessonData.title || !lessonData.description}
            onClick={() => saveLesson(lessonData)}
            className="btn btn-primary"
         >
            Submit
         </button>
      </div>
   );
};

export default AddLessonForm;
